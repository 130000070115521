import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonTypeDirective } from './button.directive';
import { rmDigitOnly } from './digit-only.directive';

@NgModule({
  declarations: [ButtonTypeDirective, rmDigitOnly],
  imports: [CommonModule],
  exports: [ButtonTypeDirective, rmDigitOnly],
})
export class DirectiveModule {}
