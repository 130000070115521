import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { ApiNotification } from 'app/services/api-notification.service';
import { Notification } from 'app/services/api.types';
import { SnackbarService } from 'app/services/snackbarService';
import { BaseListComponent } from 'app/shared/base-list';
import { RightSidebarLayoutComponent } from 'app/shared/layout/right-sidebar-layout/right-sidebar-layout.component';
import { HeaderService } from '../../header/header.service';

@Component({
  selector: 'rm-notification-list',
  templateUrl: './notification-list.template.html',
})
export class NotificationListComponent
  extends BaseListComponent<Notification>
  implements OnInit, OnDestroy
{
  @ViewChild(RightSidebarLayoutComponent) sidebar: RightSidebarLayoutComponent;

  public quickViewItem?: Notification;

  constructor(
    public api: ApiNotification,
    private snackbar: SnackbarService,
    public header: HeaderService
  ) {
    super(api);

    this.columnsToDisplay = [
      'select',
      'status',
      'title',
      'createdAt',
      'content',
      'moduleName',
      'url',
    ];
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.selection.changed.subscribe((selectionChange) => {
      const count = selectionChange.source.selected.length;
      this.bulkActions = [
        {
          value: 'markAsRead',
          name: translate(`Mark as Read`) + ` (${count})`,
        },
        {
          value: 'markAsUnread',
          name: translate(`Mark as Unread`) + ` (${count})`,
        },
      ];
    });
    this.header.breadcrumb$.next(['Home', 'Notifications']);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onBulkActionSelected = (event: string): void => {
    const count = this.selection.selected.length;
    if (count === 0) {
      return;
    }

    if (event === 'markAsRead') {
      this.api.markAs(this.concatenatedIds, 'read').subscribe(() => {
        this.dataSource = this.dataSource.map((item) => {
          if (this.selection.selected.includes(item)) {
            return {
              ...item,
              read: true,
            };
          }
          return item;
        });

        this.selection.clear();
        this.snackbar.openSnackBar({
          message: translate('Successfully marked the notifications as Read'),
          type: 'success',
        });
      });
    }

    if (event === 'markAsUnread') {
      this.api.markAs(this.concatenatedIds, 'unread').subscribe(() => {
        this.dataSource = this.dataSource.map((item) => {
          if (this.selection.selected.includes(item)) {
            return {
              ...item,
              read: false,
            };
          }
          return item;
        });

        this.selection.clear();
        this.snackbar.openSnackBar({
          message: translate('Successfully marked the notifications as Unread'),
          type: 'success',
        });
      });
    }
  };

  public showQuickview(item: Notification): void {
    this.sidebar._open();
    this.quickViewItem = item;
    this.dataSource = this.dataSource.map((data) =>
      data.id === item.id ? { ...data, read: true } : data
    );
    if (item.status === 'unread') {
      this.api.markAs(item.id as string, 'read').subscribe(() => {});
    }
  }

  public createLinkToModule(item: Notification): string {
    switch (item.moduleName) {
      case 'event':
        return `/events/details/${item.url}`;
      default:
        return `/${item.moduleName}/details/${item.url}`;
    }
  }
}
