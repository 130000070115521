import { Injectable } from '@angular/core';
import {
  Observable,
  ReplaySubject,
  BehaviorSubject,
  Observer,
  forkJoin,
  Subscription,
  Subject,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  IResponse,
  GetListParam,
  UpdateLocationParam,
  UpdateAssignedMapParam,
  AddLayoutParam,
  UpdateLayoutNameParam,
  CreateMarker,
  UpdateMarker,
  ListMarker,
  FilterPayload,
  CalibratePayload,
  LayoutMarkerPayload,
  LayoutMarkerResponse,
} from './layouts.model';
import { HeaderService } from 'app/layout/common/header/header.service';
import { RightSidebarLayoutService } from 'app/shared/layout/right-sidebar-layout/right-sidebar-layout.service';
import { FileInputService } from 'app/shared/ui-components/file-input/file-input-service';
import { getImageDimensions } from './utilities/helper';
import {
  ChargingPoint,
  ChargingPointMarker,
  Door,
  LayoutMap,
  LiftFloor,
  ListQuery,
  Map,
  PayloadTraffic,
  ResponseList,
  ResponseOne,
  ResponseWithoutList,
  Robot,
  TrafficGraph,
  Region,
  PointRegion,
  IGraphNode,
  FireHoldingPoint,
  LinkAreaTransitPoint,
  Lift,
  Layout,
} from 'app/services/api.types';
import { LayoutMarker } from 'app/services/api.types';
import { ApiLayout } from 'app/services/api-layout.service';
import { RobotMapsService } from '../robot_maps/robot_maps.service';

interface AssetsCreateResponse {
  code: number;
  error: string;
  message: string;
  result: string;
}

interface PointMarker {
  id?: string;
  name?: string;
  x: number;
  y: number;
  z: number;
}

interface LiftMarker
  extends Omit<LiftFloor, 'waitingPoint' | 'transitPoint' | 'exitPoint'> {
  liftName?: string;
  waitingPoint?: PointMarker;
  transitPoint: PointMarker;
  exitPoint?: PointMarker;
}

interface DoorMarker extends Omit<Door, 'startPoint' | 'endPoint'> {
  startPoint: PointMarker;
  endPoint: PointMarker;
  doorLines?: number;
}

interface ZoneArea extends Omit<Region, 'coordinates' | 'metadata'> {
  coordinates: PointRegion[];
  color: string;
}

@Injectable({
  providedIn: 'root',
})
export class LayoutsService {
  assetsIdValue = new ReplaySubject<string>();
  dataIcons = new BehaviorSubject<ListMarker[]>([]);
  tabLayoutEditor = new ReplaySubject<boolean>();
  toggleSidebar = new ReplaySubject<boolean>();
  selectedMarker$ = new ReplaySubject<LayoutMarker>(1);
  selectedLiftMarker$ = new ReplaySubject<LiftMarker>(1);
  selectedChargingMarker$ = new ReplaySubject<ChargingPointMarker>(1);
  selectedDoorMarker$ = new ReplaySubject<DoorMarker>(1);
  selectedZone$ = new ReplaySubject<ZoneArea>(1);
  selectedTempZoneNode$ = new ReplaySubject<PointMarker>(1);
  selectedFireHoldingMarker$ = new ReplaySubject<FireHoldingPoint>(1);
  selectedLinkAreaMarker$ = new ReplaySubject<LinkAreaTransitPoint>(1);
  listChargingPOints = new ReplaySubject<any>(1);
  graphData = new ReplaySubject<TrafficGraph>();
  isGraphDirty = new ReplaySubject<boolean>();
  canSaveGraph = new ReplaySubject<boolean>();
  liftMarkers = new ReplaySubject<LiftMarker[]>();
  toggleGraphTool = new ReplaySubject<boolean>(1);
  toggleAutoGraphTool = new ReplaySubject<any>();
  liftFloorSelectedLayout = new ReplaySubject<Layout>();
  linkAreaTransitMarkersfromServer = new BehaviorSubject<
    LinkAreaTransitPoint[]
  >([]);
  linkAreaTransitSubscription = [];
  listOfLifts = new ReplaySubject<Lift[]>();
  togglePopupBar = new Subject();
  canSaveLayout = new Subject<boolean>();
  asyncLiftMarkers = new Subject<LiftMarker>();
  activeToolHandle$ = new ReplaySubject<string>();

  private headers = {
    headers: new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
    }),
  };
  constructor(
    private http: HttpClient,
    private fileInputService: FileInputService,
    private headerService: HeaderService,
    private sidebarService: RightSidebarLayoutService,
    private apiLayout: ApiLayout,
    private robotMapService: RobotMapsService
  ) {
    this.headerService.viewTypeLayout$.subscribe((tabLayout) => {
      this.setLayoutEditor(tabLayout === 'editor');
    });
    this.sidebarService.toggleSidebar$.subscribe((sidebar) => {
      this.setSideBar(sidebar);
    });
    this.fileInputService.watchIdValue$.subscribe((idValue) => {
      this.setAssetsIdValue(idValue);
    });
  }

  getList(param: GetListParam): Observable<any> {
    const body = {
      directoryId: 0,
      pageNo: param.pageNo,
      pageSize: param.pageSize,
      searchText: param.searchText,
      sortOrder: param.sortOrder,
    };
    return this.http.post<any>('/api/v2/layout/list', body);
  }

  getLayouts(param: any): Observable<any> {
    return this.http.post<any>('/api/v2/layouts/list', param);
  }

  getSitesList(param): Observable<any> {
    const body = {
      pageNo: param.pageNo,
      pageSize: param.pageSize,
    };
    return this.http.post<any>('/api/v2/sites/list', body);
  }

  addLayout(param: AddLayoutParam): Observable<any> {
    // const body = {
    //   'parentId': param.parentId,
    //   'name': param.name,
    //   'type': param.type,
    //   'url': param.url
    // };
    return this.http.post<any>('/api/v2/layouts', param);
  }

  addSite(name: string): Observable<any> {
    const body = {
      name: name,
    };
    return this.http.post<any>('/api/v2/sites', body);
  }

  deleteLayout(names: string[]): Observable<any> {
    return this.http.request('delete', `/api/v2/layouts/${names.join()}`);
  }

  deleteDataInBulk(idArray: string[]) {
    let idList = '';
    idArray.forEach((id, i) => {
      idList += i === idArray.length - 1 ? id : `${id},`; // To test comma later
    });
    return this.http.delete(`/api/v2/layouts/${idList}`, this.headers);
  }

  updateLayoutName(
    param: UpdateLayoutNameParam
  ): Observable<ResponseOne<string>> {
    const body = {
      id: param.id,
      name: param.name,
    };
    return this.http.put<ResponseOne<string>>(
      `/api/v2/layouts/${param.id}`,
      body
    );
  }

  updateLayoutLocation(param: UpdateLocationParam): Observable<any> {
    const body = {
      id: param.id,
      location: {
        lng: param.longitude,
        lat: param.latitude,
      },
      name: param.name,
    };
    return this.http.put<any>(`/api/v2/layouts/${param.id}`, body);
  }

  updateSiteLocation(param: UpdateLocationParam): Observable<any> {
    const body = {
      id: param.id,
      location: [
        {
          type: 'Point',
          coordinates: [param.latitude, param.longitude],
        },
      ],
    };
    return this.http.put<any>('/api/v2/sites', body);
  }

  createRobotMap(
    layoutId: string,
    fileName: string,
    fileId: string
  ): Observable<any> {
    const body = {
      layoutID: layoutId,
      name: fileName,
      fileID: fileId,
    };
    return this.http.post<any>('/api/v2/map', body);
  }

  assignLayout(mapId: string, layoutId: string): Observable<any> {
    const body = {
      layoutID: layoutId,
    };
    return this.http.put<any>(`/api/v2/map/${mapId}`, body);
  }

  updateAssignedMap(param: UpdateAssignedMapParam): Observable<any> {
    return this.http.put<any>('/api/v2/map/update-assigned-map', param);
  }

  getLayoutList(name: string) {
    const body = {
      name: name,
    };
    return this.http.post('/api/v2/layout/list', body);
  }

  getLayoutsList(body) {
    return this.http.post('/api/v2/layouts/list', body);
  }

  getAssignedMaps() {
    const body = {
      pageNo: 1,
      pageSize: 100,
    };
    return this.http.post('/api/v2/map/list', body);
  }

  getRobotMaps(data: ListQuery): Observable<ResponseList<Map>> {
    return this.http.post<ResponseList<Map>>('/api/v2/map/list', data);
  }

  getLayoutMapList(
    layoutIds: string,
    mapIds: string[]
  ): Observable<ResponseOne<LayoutMap[]>> {
    const body = {
      layoutIds: [layoutIds],
      mapIds: mapIds,
    };
    return this.http.post<ResponseOne<LayoutMap[]>>(
      '/api/v2/layouts-map/list',
      body
    );
  }

  assignRobotMap(
    layoutId: string,
    mapId: string
  ): Observable<ResponseOne<string>> {
    const body = {
      layoutId: layoutId,
      mapId: mapId,
    };
    return this.http.post<ResponseOne<string>>(
      '/api/v2/layouts-map/bind',
      body
    );
  }

  unassignRobotMap(
    layoutId: string,
    mapId: string
  ): Observable<ResponseOne<string>> {
    const body = {
      layoutId: layoutId,
      mapId: mapId,
    };
    return this.http.post<ResponseOne<string>>(
      '/api/v2/layouts-map/unbind',
      body
    );
  }

  getData() {
    const pageConfig = {
      pageNo: 1,
      pageSize: 50,
    };
    return this.http.post('/api/v2/layouts/list', pageConfig, this.headers);
  }

  /** Swich Tab **/
  setLayoutEditor(tabLayoutEditor: boolean) {
    this.tabLayoutEditor.next(tabLayoutEditor);
  }

  /** Swich Tab == Observer **/
  onIsEditor(): Observable<boolean> {
    return this.tabLayoutEditor.asObservable();
  }

  /** Sidebar **/
  setSideBar(valueSidebar: boolean) {
    this.toggleSidebar.next(valueSidebar);
  }

  /** Sidebar == Observer **/
  watchSideBar(): Observable<boolean> {
    return this.toggleSidebar.asObservable();
  }

  /** List All Marker **/
  listMarkerIcon() {
    this.listMarker().subscribe((response: IResponse) => {
      if (response.code === 200) {
        if (response.result.list) {
          this.dataIcons.next(response.result.list);
        }
      }
    });
  }

  /** List Marker **/
  listMarker() {
    const pageConfig = {
      pageNo: 1,
      pageSize: 100,
      order: [
        {
          name: 'Sort by the latest created marker',
          column: 'created_at',
          type: 'desc',
        },
      ],
    };
    return this.http.post('/api/v2/icon/list', pageConfig, this.headers);
  }

  /** Fetch Marker By ID **/
  fetchMarker(markerId: any) {
    return this.http.get('/api/v2/icon/' + markerId, this.headers);
  }

  /** Create Marker **/
  createMarker(params: CreateMarker): Observable<any> {
    return this.http.post('/api/v2/icon/create', params, this.headers);
  }

  /** Update Marker **/
  updateMarker(params: UpdateMarker): Observable<any> {
    return this.http.put('/api/v2/icon/update', params, this.headers);
  }

  /** Delete Marker **/
  deleteMarker(selectedMarker: any) {
    return (
      this.http
        .delete('/api/v2/icon/' + selectedMarker.id, this.headers)
        // Delete the asset file
        .pipe(
          switchMap((response: IResponse) => {
            if (response.code === 200) {
              return this.http.delete(
                '/api/v2/asset/' + selectedMarker.description,
                this.headers
              );
            }
          })
        )
    );
  }

  /** Get Layout Data By ID **/
  getDetailLayout(layoutId: any) {
    return this.http.get('/api/v2/layouts/' + layoutId, this.headers);
  }

  /** Fetch Layout Marker By Layout Id **/
  fetchLayoutMarker(
    layoutId: string
  ): Observable<ResponseWithoutList<LayoutMarker>> {
    return this.http.get<ResponseWithoutList<LayoutMarker>>(
      `/api/v2/layout-markers/${layoutId}`,
      this.headers
    );
  }

  /** Create Layout Marker **/
  createLayoutMarker(params: LayoutMarkerPayload): Observable<any> {
    return this.http.post('/api/v2/layout-markers', params, this.headers);
  }

  /** Update Layout Marker By Layout Id **/
  updateLayoutMarker(params: LayoutMarkerPayload): Observable<any> {
    return this.http.put('/api/v2/layout-markers', params, this.headers);
  }

  /** Delete Layout Marker By Layout Id **/
  deleteLayoutMarker(idMarker: any) {
    return this.http.delete('/api/v2/layout-markers/' + idMarker, this.headers);
  }

  /** Set Id Assets **/
  setAssetsIdValue(idAssets: string) {
    this.assetsIdValue.next(idAssets);
  }

  /** Watch Id Assets **/
  watchAssetsIdValue(): Observable<string> {
    return this.assetsIdValue.asObservable();
  }

  /** New Assets API - Get Asset **/
  getAssetFile(id: string): Observable<Blob> {
    return this.http.get('/api/v2/asset/get-file/' + id, {
      responseType: 'blob',
    });
  }

  /** New Assets API - Delete Asset **/
  deleteAssetFile(id: string) {
    return this.http.delete('/api/v2/asset/' + id, this.headers);
  }

  /** upload layouts image/3D */
  uploadFile(file: File): Observable<any> {
    return this.http
      .post('/api/v2/asset/create', {
        name: file.name,
        description: file.type,
      })
      .pipe(
        switchMap((response: AssetsCreateResponse) => {
          const formData = new FormData();

          formData.append('id', response.result);
          formData.append('file', file);

          return this.http
            .post('/api/v2/asset/upload', formData, {
              reportProgress: true,
              observe: 'events',
            })
            .pipe(
              map((res: any) => ({
                ...res,
                body: { ...res.body, result: response.result },
              }))
            );
        })
      );
  }

  getAsset(id: string): Observable<any> {
    return this.http.get(`/api/v2/asset/get-info/${id}`);
  }

  removeAsset(id: string) {
    return this.http.delete(`/api/v2/asset/${id}`);
  }

  getAssignedRobotsToLayout(data: ListQuery): Observable<ResponseList<Robot>> {
    return this.http.post<ResponseList<Robot>>(
      '/api/v2/robot/list-with-layout',
      data,
      this.headers
    );
  }

  getRobotMapsByLayoutID(data: FilterPayload) {
    return this.http.post('/api/v2/map/list-with-layout', data, this.headers);
  }
  calibrateMap(data: CalibratePayload) {
    return this.http.post('/api/v2/layouts-map/calibrate', data, this.headers);
  }

  getAssetBase64(url: string): Observable<string> {
    return this.getAssetFile(url).pipe(
      switchMap(
        (blob: Blob) =>
          new Observable((observer: Observer<string>) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.addEventListener(
              'load',
              () => {
                observer.next(reader.result as string);
                observer.complete();
              },
              false
            );
          })
      )
    );
  }

  getLayoutImageByID(layoutId: string): Observable<{
    url: string;
    width: number;
    height: number;
  }> {
    return this.apiLayout.getById(layoutId).pipe(
      switchMap((response) =>
        this.getAssetBase64(`${response.result.url}_origin`).pipe(
          switchMap(async (imageUrl) => {
            const dimensions = await getImageDimensions(imageUrl);
            return {
              url: imageUrl,
              width: dimensions.width,
              height: dimensions.height,
            };
          })
        )
      )
    );
  }
  return;

  getRobotMapImageByID(robotMapId: string): Observable<{
    url: string;
    width: number;
    height: number;
  }> {
    return this.robotMapService.getDataByID(robotMapId).pipe(
      switchMap((response) =>
        this.getAssetBase64(`${response['result']['fileId']}_origin`).pipe(
          switchMap(async (imageUrl) => {
            const dimensions = await getImageDimensions(imageUrl);
            return {
              url: imageUrl,
              width: dimensions.width,
              height: dimensions.height,
            };
          })
        )
      )
    );
  }

  getTrafficGraphbyLayoutId(id: string): Observable<TrafficGraph> {
    return this.http.get<TrafficGraph>(`api/v2/graph?layoutId=${id}`);
  }

  createTrafficGraphbyLayoutId(
    payload: PayloadTraffic
  ): Observable<PayloadTraffic> {
    return this.http.post<PayloadTraffic>(
      'api/v2/graph/create',
      payload,
      this.headers
    );
  }

  /*
   * Clear the graph cache,
   * should be used before user modify traffic graph from the graph editor
   *
   */
  clearGraphCache(companyId: string): Observable<ResponseOne<any>> {
    const url = `/path/clean/graph?companyId=${companyId}`;
    return this.http.get<ResponseOne<any>>(url);
  }

  /**
   * Get layout details
   * @param id layoutId
   */
  getLayoutbyId(layoutId: string) {
    return new Promise((resolve) => {
      this.apiLayout.getById(layoutId).subscribe((resp) => {
        if (resp.code === 200) {
          resolve(resp.result);
        }
      });
    });
  }
}
