import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HeaderService } from 'app/layout/common/header/header.service';
import {
  Event,
  FullPathPlanner,
  FullPathPlannerPayload,
  ResponseWithoutList,
} from 'app/services/api.types';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { monitoringEventService } from './components/event/monitoring-event.service';
import { ITrafficGraph } from './map/building-map/traffic-graph.model';
import { EventWithImage, Sidebar } from './monitor.model';

@Injectable({
  providedIn: 'root',
})
export class MonitorService {
  _is3D: boolean = true;
  is3D = new ReplaySubject<boolean>();
  _openSideBar: boolean = false;
  openSideBar = new BehaviorSubject<boolean>(false);
  _sidebarData: Sidebar;

  sideBarData = new ReplaySubject<Sidebar>();
  teleOperations = new Subject<boolean>();
  _layoutId: string = '';
  layoutId = new ReplaySubject<string>();
  eventType$ = new Subject<number>();
  missionType$ = new Subject<number>();
  jobType$ = new Subject<number>();
  _currentRobotId: string;
  _robotClickEvent: string;
  currentRobotId$ = new ReplaySubject<string>();
  clickedEventId$ = new Subject<string>();
  refreshCriticalEvents$ = new Subject<boolean>();
  refreshNormalEvents$ = new Subject<boolean>();
  clickedMissionId$ = new Subject<string>();
  // _showPlannedpath: boolean = false;
  // showPlannedpath = new ReplaySubject<boolean>();
  currentTrafficGraph: ITrafficGraph;
  currentRobotLocation = [];
  layoutRobotList;
  robotPlannedPaths = [];
  cacheRobotDetails = {};
  robotPalnnedPaths$ = new ReplaySubject<[]>();
  _newPathRobotId: string;
  newPathRobotId$ = new ReplaySubject<string>();
  eventStatus$ = new ReplaySubject<EventWithImage>(1);

  constructor(
    private _headerService: HeaderService,
    private _http: HttpClient,
    private _monitoringEventService: monitoringEventService
  ) {
    this._headerService.viewType$.subscribe((viewType) => {
      this.setIs3D(viewType === '3d');
    });
  }

  getCurrentRobotId(): Observable<string> {
    return this.currentRobotId$;
  }

  getSelectedRobotId(): string {
    return this._currentRobotId;
  }

  getLayoutId(): string {
    return this._layoutId;
  }

  setLayoutId(id: string) {
    this._layoutId = id;
    this.layoutId.next(id);
  }

  setCurrentRobotId(robotId: string, click?: string): void {
    this._currentRobotId = robotId;
    this._robotClickEvent = click;
    this.currentRobotId$.next(this._currentRobotId);
  }

  getNewPathRobotId(): Observable<string> {
    return this.newPathRobotId$;
  }

  setNewPathRobotId(robotId: string): void {
    this._newPathRobotId = robotId;
    this.newPathRobotId$.next(this._newPathRobotId);
  }

  getRbtClickEvt() {
    return this._robotClickEvent;
  }

  setClickedEventId(eventId: string) {
    this.clickedEventId$.next(eventId);
  }

  getIs3D(): boolean {
    return this._is3D;
  }

  onIs3D(): Observable<boolean> {
    return this.is3D.asObservable();
  }

  setIs3D(is3D: boolean): void {
    this._is3D = is3D;
    this.is3D.next(this._is3D);
  }

  getIsOpened() {
    return this._openSideBar;
  }

  setSideBarOpen(opened: boolean) {
    this._openSideBar = opened;
    this.openSideBar.next(this._openSideBar);
  }

  getSideBarData(data) {
    return this._openSideBar;
  }

  setSideBarData(data: Sidebar) {
    this._sidebarData = data;
    this.sideBarData.next(this._sidebarData);
  }

  // showPlannedPath() {
  //   this._showPlannedpath = !this._showPlannedpath;
  //   this.showPlannedpath.next(this._showPlannedpath);
  // }

  getRobotPlannedPath() {
    return this.robotPlannedPaths;
  }

  setRobotPlannedPath(data, id) {
    this.robotPlannedPaths = data;
    this._newPathRobotId = id;
    this.newPathRobotId$.next(this._newPathRobotId);
    this.robotPalnnedPaths$.next(data);
  }

  public eventClickEvent(eventId: string) {
    // if (!eventId) {
    //     this.setSideBarOpen(false);
    //     return;
    // }
    // let eventId: string = '';
    if (eventId) {
      // if (eventId.includes('world-')) {
      //     eventId = eventId.replace('world-', '');
      // } else if (eventId.includes('layout-')) {
      //     eventId = eventId.replace('layout-', '');
      // }
      // this.setSideBarOpen(true);
      this.getCurrentEvent(eventId).subscribe((data: any) => {
        if (data.code === 200) {
          this._monitoringEventService.setCurrentEventId(eventId);
          const sidebarData = {
            title: data.result.name,
            type: 'event-details',
            tab: 0,
            actionName: '',
          };
          this.setSideBarData(sidebarData);
          this.setSideBarOpen(true);
        } else {
          this.setSideBarOpen(false);
          console.error(data.code);
        }
      });
    }
  }

  getCameraList(id) {
    return this._http.get(`api/v2/camera/list/${id}`);
  }

  getCurrentEvent(id) {
    const psd = {
      pageNo: 1,
      pageSize: 10,
      filter: [
        {
          name: 'Create At',
          column: 'events.Created_at',
          operator: 'ge',
          value: [new Date()],
        },
        {
          name: 'Robot Id',
          column: 'events.robot_id',
          operator: 'eq',
          value: [id],
        },
      ],
      order: [
        {
          name: 'createdTime',
          column: 'events.Created_at',
          type: 'desc',
        },
      ],
    };
    return this._http.post('/api/v2/events/list', psd);
  }

  getCurrentMission(id) {
    const psd = {
      pageNo: 1,
      pageSize: 10,
      filter: [
        {
          name: 'Status',
          column: 'missions.status',
          operator: 'eq',
          value: ['5'],
          extra: 'data_dict("MissionStatus")',
          dataType: 'enum',
          virtual: false,
        },
        {
          name: 'robotId',
          column: 'robot_missions.robot_id',
          operator: 'eq',
          value: [id],
          extra: '',
          dataType: 'text',
          virtual: false,
        },
      ],
      order: [
        {
          name: 'Update Time',
          column: 'updated_at',
          type: 'desc',
        },
      ],
    };
    return this._http.post('api/v2/mission/list-join-robot', psd);
  }

  getFile(name: string): Observable<Blob> {
    const url = name + '_origin';
    return this._http.get('/api/v2/asset/get-file/' + url, {
      responseType: 'blob',
    });
  }

  get3DFile(id: string): Observable<Blob> {
    return this._http.get('/api/v2/asset/get-file/' + id, {
      responseType: 'blob',
    });
  }

  get3DFileInfo(id: string) {
    return this._http.get(`/api/v2/asset/get-info/${id}`);
  }

  getTrafficGraphbyId(id: string) {
    return this._http.get(`/api/v2/graph?layoutId=${id}`);
  }

  getPlannedPath(payload: object) {
    return this._http.post('/api/v2/path/planning', payload);
  }

  public getFullPlannedPath(
    payload: FullPathPlannerPayload
  ): Observable<ResponseWithoutList<FullPathPlanner>> {
    return this._http.post<ResponseWithoutList<FullPathPlanner>>(
      '/path/full/plan',
      payload
    );
  }
}
