import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class monitoringEventService {
  _currentEventId: string;
  currentEventId$ = new BehaviorSubject<string>('');
  constructor() {}

  getCurrenEventId() {
    return this._currentEventId;
  }

  setCurrentEventId(eventId: string): void {
    this._currentEventId = eventId;
    this.currentEventId$.next(this._currentEventId);
  }
}
