import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileInputComponent } from './file-input.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ActionButtonModule } from '../action-button/action-button.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { FileSizePipe } from './filesize.pipe';

@NgModule({
  declarations: [FileInputComponent, FileSizePipe],
  imports: [
    CommonModule,
    NgxDropzoneModule,
    ActionButtonModule,
    MatProgressBarModule,
    TranslocoRootModule,
  ],
  exports: [FileInputComponent],
})
export class FileInputModule { }
