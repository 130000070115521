import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SignInService {
  authenticated: boolean = false;

  constructor(private http: HttpClient) {}

  set accessToken(token: string) {
    sessionStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return sessionStorage.getItem('accessToken') ?? '';
  }

  private _options = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  login(loginData) {
    const body = loginData;
    return this.http.post('' + '/api/v2/login', body, this._options);
  }

  // google login
  googleLoginGetToken(data) {
    // return this.http.post('' + '/api/LoginWithGoogleAccount', data, this._options);
  }

  getCompanies(accessKey) {
    return this.http.post('/api/v2/get-companies', accessKey);
  }

  verifyUserName(loginData) {
    return this.http.post('' + 'api/v2/verify/user', loginData, this._options);
  }

  getMqttInfo() {
    return this.http.get('' + 'api/v2/mqtt/loginInfo', this._options);
  }

  check(): Observable<boolean> {
    if (this.accessToken) {
      //this.authenticated
      return of(true);
    }

    if (!this.accessToken) {
      return of(false);
    }
    return of(true);
  }

  switchCompany(companyId) {
    return this.http.post('' + 'api/v2/swithCompany', { companyId });
  }

  verifyUserMfa(loginData) {
    return this.http.post('' + 'api/v2/twofa/check', loginData, this._options);
  }

  registerMfa(registerData) {
    return this.http.post('api/v2/twofa/register', registerData, this._options);
  }

  verifyMfa(registerData) {
    return this.http.post('api/v2/twofa/verify', registerData, this._options);
  }

  getMfaInfo(payload) {
    return this.http.post('api/v2/twofa/info', payload, this._options);
  }
}
