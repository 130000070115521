import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SnackbarService } from 'app/services/snackbarService';

@Component({
  selector: 'thumbnail-card',
  templateUrl: './thumbnail-card.component.html',
  styleUrls: ['./thumbnail-card.component.scss'],
})
export class ThumbnailCardComponent implements OnInit {
  @Input() item: any;
  @Input() stickTitle: boolean;
  @Input() addRemove: boolean = false;
  @Input() checkBox: boolean = false;
  @Input() enableRename: boolean;
  @Input() enableSetLocation: boolean;
  @Output() clickEvent = new EventEmitter();
  @Output() clickImage = new EventEmitter();
  @Output() clickProperties = new EventEmitter();
  @Output() checkEntity = new EventEmitter();
  @Output() changeName = new EventEmitter();
  @Output() clickAddRemove = new EventEmitter();
  @Output() clickLocation = new EventEmitter();
  @Output() clickRobot = new EventEmitter();
  @Output() clickMap = new EventEmitter();

  public isRename: boolean = false;
  prevName: string;

  constructor(private snackBar: SnackbarService) {}
  ngOnInit(): void {
    this.isRename = this.item ? (this.item.isNew ? true : false) : false;
  }

  cancel() {
    this.item.name = this.prevName;
    this.toggleRename();
  }

  toggleRename(): void {
    this.isRename = !this.isRename;
    this.prevName = this.item.name;
  }

  handleChangeName(): void {
    if (!this.item.name || this.item.name.length > 50) {
      this.snackBar.openSnackBar({
        message: 'this field is required and the word count is less than 50. ',
        type: 'failed',
      });
    } else {
      this.toggleRename();
      this.changeName.emit({
        id: this.item.id,
        name: this.item.name,
      });
    }
  }

  updateItemTitle(name: string): void {
    this.item.name = name.trim();
  }

  handleAction(): void {
    this.clickEvent.emit();
  }

  handleImageClick(id: string): void {
    this.clickImage.emit(id);
  }

  handleCheck(id: string): void {
    this.checkEntity.emit(id);
    this.item.assigned = !this.item.assigned;
  }

  handleAddRemove(item): void {
    this.clickAddRemove.emit(item);
  }

  handleLocation(item): void {
    this.clickLocation.emit(item);
  }

  handleRobot(id: string): void {
    this.clickRobot.emit(id);
  }

  handleMap(id: string): void {
    this.clickMap.emit(id);
  }
}
