import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from 'app/app.settings';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import jwtDecode from 'jwt-decode';
import { Observable, ReplaySubject, Subject, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class AuthService {
  private _authenticated: boolean = false;
  // Subject to notify about unauthorized events
  private unauthorizedSubject = new Subject<void>();

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService,
    private _appSettings: AppSettings
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    sessionStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return sessionStorage.getItem('accessToken') ?? '';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<any> {
    return this._httpClient.post('api/auth/forgot-password', email);
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(password: string): Observable<any> {
    return this._httpClient.post('api/auth/reset-password', password);
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { email: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this._httpClient
      .post('api/token-auth', {
        username: credentials.email,
        password: credentials.password,
      })
      .pipe(
        switchMap((response: any) => {
          // Store the access token in the local storage
          this.accessToken = response.token;

          // Set the authenticated flag to true
          this._authenticated = true;

          // TODO
          // Store the user on the user service
          return of(response);
        })
      );
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    // Renew token
    return this._httpClient
      .post('api/auth/refresh-access-token', {
        accessToken: this.accessToken,
      })
      .pipe(
        catchError(() =>
          // Return false
          of(false)
        ),
        switchMap((response: any) => {
          // Store the access token in the local storage
          this.accessToken = response.accessToken;

          // Set the authenticated flag to true
          this._authenticated = true;

          // Store the user on the user service
          this._userService.user = response.user;

          // Return true
          return of(true);
        })
      );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    const setting = this._appSettings.settings;
    setting.loadingSpinner = true;
    const token = sessionStorage.getItem('accessToken');
    // Remove the access token from the local storage
    sessionStorage.removeItem('accessToken');
    localStorage.removeItem('cucid');
    localStorage.removeItem('mqttInfoms');
    sessionStorage.removeItem('menus');

    // Set the authenticated flag to false
    this._authenticated = false;
    this._appSettings.loginFlag = false;
    // Return the observable
    // return of(true);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token,
      }),
    };
    setting.loadingSpinner = false;

    return this._httpClient.get('api/v2/logout', httpOptions).pipe(
      catchError(() =>
        // Return false
        of(false)
      ),
      switchMap((response: any) => {
        // remove the permitted resources from local storage when user signing out
        // localStorage.removeItem(permittedResourcesConst);
        setting.loadingSpinner = false;

        // Return true
        return of(response);
      })
    );
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: {
    name: string;
    email: string;
    password: string;
    company: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/sign-up', user);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: {
    email: string;
    password: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    // If the access token exists and it didn't expire, sign in using it
    return this.signInUsingToken();
  }

  get userId(): string {
    return jwtDecode<Jwt>(this.accessToken).sub;
  }

  get companyId(): string {
    return jwtDecode<Jwt>(this.accessToken).companyId;
  }

  get role(): string {
    if (this.accessToken) {
      const role = jwtDecode<Jwt>(this.accessToken).role;
      return role;
    }
  }

  getConfig() {
    return this._httpClient.get('/assets/config/config.json');
  }

  get isOwner(): boolean {
    return (
      jwtDecode<Jwt>(this.accessToken).owner ===
      jwtDecode<Jwt>(this.accessToken).companyId
    );
  }

  // Method to get unauthorized observable
  getUnauthorizedObservable(): Observable<void> {
    return this.unauthorizedSubject.asObservable();
  }

  // Method to handle unauthorized events
  handleUnauthorized(): void {
    // Notify subscribers about unauthorized event
    this.unauthorizedSubject.next();
  }

  getSystemNotification() {
    return this._httpClient.get('/api/system/notifacation');
  }
}

interface Jwt {
  sub: string;
  userName: string;
  owner: string;
  companyId: string;
  role: string;
}
