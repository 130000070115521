/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ApiLayoutMarker } from 'app/services/api-layout-marker.service';
import {
  ChargingPointMarker,
  GraphNodeWaitingPoint,
  IGraphNode,
  LayoutMarker,
  LayoutSensor,
  LiftFloor,
  PointRegion,
  Region,
  FireHoldingPoint,
  LinkAreaTransitPoint,
} from 'app/services/api.types';
import {
  circle,
  CircleMarker,
  CircleMarkerOptions,
  control,
  CRS,
  divIcon,
  Icon,
  icon,
  ImageOverlay,
  imageOverlay,
  LatLng,
  LatLngBounds,
  LatLngExpression,
  Layer,
  layerGroup,
  LayerOptions,
  LeafletEvent,
  LeafletEventHandlerFn,
  LeafletMouseEvent,
  Map,
  Marker,
  marker,
  MarkerOptions,
  Point,
  PointExpression,
  PointTuple,
  polygon,
  Polygon,
  polyline,
  Polyline,
  PolylineOptions,
  Tooltip,
  TooltipOptions,
} from 'leaflet';
import 'leaflet.marker.slideto';
import _, { findLastIndex, includes, update } from 'lodash';
import { get } from 'lodash';
import {
  AVAILABLE_ZONE_AREA_COLOR,
  MARKER_TYPE,
} from '../utilities/marker-constanta';
import { Circle } from 'leaflet';
import { v4 as uuidv4 } from 'uuid';
import { className } from '@babylonjs/core';
import { LayoutsService } from '../layouts.service';

const OVERLAY_IMAGE_WIDTH = 3000;
const OVERLAY_IMAGE_HEIGHT = 1500;
const OVERLAY_IMAGE_OPTIONS = { opacity: 1 };

interface MapOptions {
  mapWidth: number;
  mapHeight: number;
  center: PointExpression;
  isShowLabels: boolean;
  editable?: boolean;
}

interface ZoneArea extends Omit<Region, 'coordinates' | 'metadata'> {
  coordinates: PointRegion[];
  color: string;
}

@Injectable({
  providedIn: 'root',
})
export class LeafletService {
  public map: Map;
  protected _options: Partial<MapOptions> = {};
  protected _gridImage: ImageOverlay;
  protected _markersGroup = layerGroup();
  protected _markers: { [markerId: string]: Marker } = {};
  protected _sensorsGroup = layerGroup();
  protected _floorsGroup = layerGroup();
  protected _sensors: { [markerId: string]: Marker } = {};
  protected _floors: { [markerId: string]: Marker } = {};
  protected _floorsTransit: { [markerId: string]: Marker } = {};
  protected _floorsWaiting: { [markerId: string]: Marker } = {};
  protected _floorsExit: { [markerId: string]: Marker } = {};
  protected _robotsGroup = layerGroup();
  protected _robots: { [robotId: string]: Marker } = {};
  protected _labelsGroup = layerGroup();
  protected _eventsGroup = layerGroup();
  protected _eventNamesGroup = layerGroup();
  protected _liftsGroup = layerGroup();
  protected _lifts: { [markerId: string]: Marker } = {};
  protected _polylinesGroup = layerGroup();
  protected _polylines: { [lineId: string]: Polyline } = {};
  protected _tooltipsGroup = layerGroup();
  protected _tooltips: { [lineId: string]: Tooltip } = {};
  protected _chargersGroup = layerGroup();
  protected _chargers: { [markerId: string]: Marker } = {};
  protected _doorsGroup = layerGroup();
  protected _doors: { [markerId: string]: Marker } = {};
  protected _doorLinesGroup = layerGroup();
  protected _doorLines: { [lineId: string]: Polyline } = {};
  protected _doorTypeLinesGroup = layerGroup();
  protected _doorTypeLines: { [lineId: string]: Polyline } = {};
  protected _zoneNodesGroup = layerGroup();
  protected _zoneNodes: { [markerId: string]: Marker } = {};
  protected _zonesGroup = layerGroup();
  protected _zones: { [markerId: string]: Polygon } = {};
  protected _tempZonesGroup = layerGroup();
  protected _trackingRadiusGroup = layerGroup();
  protected _trackingRadius: { [markerId: string]: Circle } = {};
  protected _trackingWaitingPointGroup = layerGroup();
  protected _trackingWaitingPoints: { [markerId: string]: Marker } = {};
  public _activeLane: any = {};
  public _actioveNodes: Array<any> = [];
  /* ==== fire alarm marker group ==== */
  protected _fireHoldingGroup = layerGroup();
  protected _fireHoldingNodes: {
    [markerId: string]: Marker<FireHoldingPoint>;
  } = {};

  protected _linkAreaTransitGroup = layerGroup();
  protected _linkAreaTransitNodes: {
    [markerId: string]: Marker<FireHoldingPoint>;
  } = {};

  protected _tempGraph = layerGroup();
  public _templastNode: IGraphNode = undefined;
  public _tempTransitGraphNodes = {};
  public _liftMarkerIdMapNodesId = {};
  public _tempLane;
  public _tempZone: Polygon;
  public _savedGraph = layerGroup();
  public allNodes = {};
  public allLanes = {};
  public allMetaLanes = {};
  public savedNodes = {};
  // public mapClick = true;
  protected _drawnLanes = [];
  public _showGraph = true;
  public isMarkerClick = true;

  constructor(
    protected apiLayoutMarker: ApiLayoutMarker,
    protected translocoService: TranslocoService,
    protected layoutsService: LayoutsService,
  ) {}

  initMap(map: Map, options: Partial<MapOptions> = {}): void {
    setTimeout(() => map.invalidateSize(), 200);

    map.addControl(
      control.attribution({
        position: 'bottomright',
        prefix: 'Robot Manager',
      })
    );
    map.setMinZoom(-5);
    map.setMaxZoom(5);

    map.addLayer(this._markersGroup);
    map.addLayer(this._sensorsGroup);
    map.addLayer(this._floorsGroup);
    map.addLayer(this._robotsGroup);
    map.addLayer(this._labelsGroup);
    map.addLayer(this._eventsGroup);
    map.addLayer(this._eventNamesGroup);
    map.addLayer(this._liftsGroup);
    map.addLayer(this._polylinesGroup);
    map.addLayer(this._tooltipsGroup);
    map.addLayer(this._chargersGroup);
    map.addLayer(this._doorsGroup);
    map.addLayer(this._doorLinesGroup);
    map.addLayer(this._doorTypeLinesGroup);
    map.addLayer(this._zoneNodesGroup);
    map.addLayer(this._zonesGroup);
    map.addLayer(this._tempZonesGroup);
    map.addLayer(this._trackingRadiusGroup);
    map.addLayer(this._trackingWaitingPointGroup);
    map.addLayer(this._fireHoldingGroup);
    map.addLayer(this._linkAreaTransitGroup);

    this.map = map;
    this._options = options;

    if (options.center) {
      const latLng = this.map.unproject(options.center, this.map.getZoom());
      this.map.setView(latLng, this.map.getZoom());
    }
  }

  loadMapImage(
    images: string,
    width?: number,
    height?: number,
    isMiniMap: boolean = false
  ): void {
    this._options.mapWidth = width || OVERLAY_IMAGE_WIDTH;
    this._options.mapHeight = height || OVERLAY_IMAGE_HEIGHT;

    const mapBounds = this.getImageBounds();
    const backgroundimage = imageOverlay(
      images,
      mapBounds,
      OVERLAY_IMAGE_OPTIONS
    );
    if (!isMiniMap) {
      this.map.fitBounds(mapBounds, {
        animate: false,
        padding: [10, 10],
      });
    } else {
      this.map.setZoom(-2);
    }
    this.map.addLayer(backgroundimage);
  }

  getImageBounds(offset = 0): LatLngBounds {
    const width = this._options.mapWidth;
    const height = this._options.mapHeight;
    const southWest = this.map.unproject([0 - offset, height + offset], 1);
    const northEast = this.map.unproject([width + offset, 0 - offset], 1);
    return new LatLngBounds(southWest, northEast);
  }

  setGridImage(imageUrl: string): void {
    this._gridImage = imageOverlay(
      imageUrl,
      this.getImageBounds(),
      OVERLAY_IMAGE_OPTIONS
    );
  }

  setShowGrid(showGrid: boolean): void {
    if (showGrid) {
      this.map.addLayer(this._gridImage);
    } else {
      this.map.removeLayer(this._gridImage);
    }
  }

  setZoom(zoom: number): void {
    this.map.setZoom(zoom);
  }

  public renderLayoutMarkers(
    lMarkers: LayoutMarker[],
    options: Partial<RmMarkerOptions>
  ): void {
    lMarkers.forEach((lMarker) => {
      this.renderLayoutMarker(lMarker, options);
    });
  }

  public renderSensorsMarkers(
    sMarkers: LayoutSensor[],
    options: Partial<RmMarkerOptions>
  ): void {
    sMarkers.forEach((sMarker) => {
      this.renderSensorMarker(sMarker, options);
    });
  }

  public renderFloorsMarkers(
    fMarkers: LiftFloor[],
    options: Partial<RmMarkerOptions>,
    type: string
  ): void {
    fMarkers.forEach((fMarker) => {
      this.renderFloorMarker(fMarker, options, type);
      // this.renderFloorMarker(fMarker, options, type);
    });
  }

  /**
   * Add marker to a map
   *
   * @param point
   * @param options
   */
  public renderLayoutMarker(
    lMarker: LayoutMarker,
    options: Partial<RmMarkerOptions>
  ): void {
    // first, try to find if layout Marker is already in list of markers.
    // if no, create new marker. Else, update the leaflet marker
    const found = this.findMarkerFromLayoutMarker(lMarker);
    if (found) {
      // update the marker
      this.updateMarker2(found, lMarker);
      return;
    }

    // Create. Then, set the icon and color
    const lfMarker = this.createMarker(lMarker, options);
    this.setMarkerIconFromLayoutMarker(lfMarker, lMarker);

    // add label
    // if (this._options.isShowLabels) {
    //   this.addLabel(latlng, options.label);
    // }
  }

  public renderSensorMarker(
    sMarker: LayoutSensor,
    options: Partial<SensorMarkerOptions>
  ): void {
    const found = this.findSensorFromSensorMarker(sMarker);
    if (found) {
      // update the marker
      this.updateSensor2(found, sMarker);
      return;
    }

    // Create. Then, set the icon and color
    const sfMarker = this.createSensorMarker(sMarker, options);
    this.setSensorIcon(sfMarker, 'indigo', sMarker.name);

    // add label
    // if (this._options.isShowLabels) {
    //   this.addLabel(latlng, options.label);
    // }
  }

  public renderFloorMarker(
    fMarker: LiftFloor,
    options: Partial<FloorMarkerOptions>,
    type: string
  ): void {
    const found = this.findFloorFromFloorMarker(fMarker, type);
    if (found) {
      // update the marker
      if (type === MARKER_TYPE.transitPoint) {
        this.updateFloorTransit(found, fMarker, type);
        return;
      }
      if (type === MARKER_TYPE.waitingPoint) {
        this.updateFloorWaiting(found, fMarker, type);
        return;
      }
    }
    if (type === MARKER_TYPE.transitPoint) {
      // Create. Then, set the icon and color
      const ffTransitMarker = this.createFloorTransitMarker(fMarker, options);
      this.setFloorIcon(
        ffTransitMarker,
        'green',
        // fMarker.userLevel,
        fMarker.transitPoint.name,
        'Lift'
      );
    }

    if (type === MARKER_TYPE.waitingPoint) {
      // Create. Then, set the icon and color
      const ffWaitingMarker = this.createFloorWaitingMarker(fMarker, options);
      this.setFloorIcon(
        ffWaitingMarker,
        'red',
        // fMarker.userLevel,
        fMarker.waitingPoint.name,
        'Waiting Point'
      );
    }

    if (type === MARKER_TYPE.exitPoint) {
      // Create. Then, set the icon and color
      const ffExitMarker = this.createFloorExitMarker(fMarker, options);
      this.setFloorIcon(
        ffExitMarker,
        'red',
        // fMarker.userLevel,
        fMarker.exitPoint.name,
        'Exit Point'
      );
    }
    // add label
    // if (this._options.isShowLabels) {
    //   this.addLabel(latlng, options.label);
    // }
  }

  /**
   * Add marker to a map
   *
   * @param point - Marker
   * @param options - Marker Options
   */
  public renderChargingMarker(
    cMarker: ChargingPointMarker,
    options: Partial<RmMarkerOptions>
  ): void {
    // first, try to find if charging Marker is already in list of markers.
    // if no, create new marker. Else, update the leaflet marker
    const found = this.findChargingFromChargingMarker(cMarker);

    if (found) {
      // update the marker
      this.updateChargingMarker(found, cMarker);
      return;
    }

    // Create. Then, set the icon and color
    const cfMarker = this.createChargingMarker(cMarker, options);
    this.setChargerIcon(cfMarker, options.color, cMarker.name);
  }

  /**
   * Add a fire holding point
   * @param marker - LayoutMarker
   */
  public renderFireHoldingMarker(
    _marker: FireHoldingPoint,
    options: Partial<RmMarkerOptions>
  ): void {
    const found = this._fireHoldingNodes[_marker.id] ?? null;

    if (found) {
      this.updateHoldingMarker(found, _marker);
      return;
    }
    const fireHoldingMarker = this.createFireHoldingMarker(_marker, options);

    this.setFireHoldingIcon(
      fireHoldingMarker,
      _marker.metadata.color,
      _marker.name
    );
  }

  public renderFireHoldingMarkers(
    _markers: FireHoldingPoint[],
    options: Partial<RmMarkerOptions>
  ): void {
    _markers.forEach((item) => this.renderFireHoldingMarker(item, options));
  }

  public renderLinkareaMarkers(
    _markers: LinkAreaTransitPoint[],
    options: Partial<RmMarkerOptions>
  ): void {
    _markers.forEach((item) => this.renderLinkAreaTransitMarker(item, options));
  }

  public updateHoldingMarker(
    oldMarker: Marker,
    updateMarker: FireHoldingPoint
  ): void {
    this.setFireHoldingIcon(
      oldMarker,
      updateMarker.metadata.color,
      updateMarker.name
    );
    const coordinates: PointExpression = [
      updateMarker.position.x,
      updateMarker.position.y,
    ];
    const latlng = this.xYtoLatlng(coordinates);
    oldMarker.setLatLng(latlng);
  }

  private setFireHoldingIcon = (
    selectedMarker: Marker,
    color: string = 'dangerRed',
    label?: string
  ): void => {
    const imageUrl: string = '/assets/images/markers/verified_user.svg';
    const htmlIcon = divIcon({
      className: 'custom-marker-pin',
      html: `
        <span class="marker-label">${label ?? ''}</span>
        <div class="marker-pin ${color}">
          <div class="marker-icon marker-icon-fire-holding ${
            imageUrl ?? 'hidden'
          }">
            <img src="${imageUrl}">
          </div>
        </div>
        <div class="marker-shadow"></div>
      `,
      iconSize: [64, 64],
      iconAnchor: [64 / 2, 64],
    });
    selectedMarker.setIcon(htmlIcon);
  };

  private createFireHoldingMarker(
    _marker: FireHoldingPoint,
    options: Partial<RmMarkerOptions>
  ) {
    const point: PointTuple = [_marker.position.x, _marker.position.y];

    options = {
      ...options,
      markerId: _marker.id,
      title: _marker.name,
    };
    const latlng = this.xYtoLatlng(point);
    const faMarker = marker(latlng, options);
    if (options.click) {
      faMarker.on('click', (event) => {
        options.click(event);
      });
    }
    if (options.dragstart) {
      faMarker.on('dragstart', (event) => {
        options.dragstart(event);
      });
    }
    if (options.dragend) {
      faMarker.on('dragend', (event) => {
        options.dragend(event);
      });
    }

    this._fireHoldingGroup.addLayer(faMarker);
    this._fireHoldingNodes[_marker.id] = faMarker;

    return faMarker;
  }

  protected setMarkerIconFromLayoutMarker(
    lfMarker: Marker,
    lMarker: LayoutMarker
  ): void {
    const iconId = lMarker.marker?.icon;
    const color = lMarker.metadata?.color ?? 'red';

    if (iconId) {
      this.apiLayoutMarker.getAssetPublicUrl(iconId).subscribe((iconUrl) => {
        this.setMarkerIcon(lfMarker, iconUrl, color, lMarker.name, lMarker?.metadata);
      });
    } else {
      if (color !== 'violet' || lMarker?.metadata?.type === 'marker') {
        this.setMarkerIcon(
          lfMarker,
          null,
          color,
          lMarker.name,
          lMarker?.metadata
        );
      } else if (color === 'violet' && lMarker?.metadata?.type !== 'marker') {
        this.setLinkAreaIcon(lfMarker, lMarker.metadata.type, lMarker.name);
      }
    }
  }

  protected findMarkerFromLayoutMarker(lMarker: LayoutMarker): Marker | null {
    return this._markers[lMarker.id] ?? null;
  }

  protected findSensorFromSensorMarker(sMarker: LayoutSensor): Marker | null {
    return this._sensors[sMarker.id] ?? null;
  }

  protected findFloorFromFloorMarker(fMarker: LiftFloor, type): Marker | null {
    // return this._floors[fMarker.id] ?? null;
    if (type === MARKER_TYPE.transitPoint) {
      return this._floorsTransit[fMarker.id] ?? null;
    }
    if (type === MARKER_TYPE.waitingPoint) {
      return this._floorsWaiting[fMarker.id] ?? null;
    }
    if (type === MARKER_TYPE.exitPoint) {
      return this._floorsExit[fMarker.id] ?? null;
    }
  }

  protected findChargingFromChargingMarker(
    cMarker: ChargingPointMarker
  ): Marker | null {
    return this._chargers[cMarker.id] ?? null;
  }

  protected createMarker(
    lMarker: LayoutMarker,
    options: Partial<RmMarkerOptions>
  ): Marker {
    // create new marker
    const lPoint: PointTuple = [lMarker.position.x, lMarker.position.y];
    options = {
      ...options,
      markerId: lMarker.id,
      iconId: lMarker.marker?.icon ?? null,
      title: lMarker.name,
      color: lMarker.metadata?.color ?? 'grey',
    };

    // unproject the Point to latlng using zoom level of 1
    const latlng = this.xYtoLatlng(lPoint);

    // create the marker and the events
    const lfMarker = marker(latlng, options);
    if (options.click) {
      lfMarker.on('click', (event) => {
        options.click(event);
        this.resetLaneAndNodeStyle();
      });
    }
    if (options.dragstart) {
      lfMarker.on('dragstart', (event) => {
        options.dragstart(event);
        this.resetLaneAndNodeStyle();
      });
    }
    if (options.dragend) {
      lfMarker.on('dragend', options.dragend);
    }

    // add to markers group
    this._markersGroup.addLayer(lfMarker);

    // add to the index
    this._markers[lMarker.id] = lfMarker;

    return lfMarker;
  }

  protected createSensorMarker(
    sMarker: LayoutSensor,
    options: Partial<SensorMarkerOptions>
  ): Marker {
    // create new marker
    const sPoint: PointTuple = [sMarker.coordinate.x, sMarker.coordinate.y];
    options = {
      ...options,
      markerId: sMarker.id,
      title: sMarker.name,
    };

    // unproject the Point to latlng using zoom level of 1
    const latlng = this.xYtoLatlng(sPoint);

    // create the marker and the events
    const sfMarker = marker(latlng, options);
    if (options.click) {
      sfMarker.on('click', (event) => {
        options.click(event);
        this.resetLaneAndNodeStyle();
      });
    }
    if (options.dragstart) {
      sfMarker.on('dragstart', (event) => {
        options.dragstart(event);
        this.resetLaneAndNodeStyle();
      });
    }
    if (options.dragend) {
      sfMarker.on('dragend', options.dragend);
    }

    // add to markers group
    this._sensorsGroup.addLayer(sfMarker);

    // add to the index
    this._sensors[sMarker.id] = sfMarker;

    return sfMarker;
  }

  protected createFloorTransitMarker(
    fMarker: LiftFloor,
    options: Partial<FloorMarkerOptions>
  ): Marker {
    // create new marker
    const fTransitPoint: PointTuple = [
      fMarker.transitPoint.x,
      fMarker.transitPoint.y,
    ];

    // fMarker.id = createUuid();

    options = {
      ...options,
      // markerId: fMarker.id,
      markerId: fMarker.transitPoint['id'],
      title: fMarker.userLevel,
    };

    // unproject the Point to latlng using zoom level of 1
    const latlngTransit = this.xYtoLatlng(fTransitPoint);

    // create the marker and the events
    const ffMarkerTransit = marker(latlngTransit, options);

    if (options.click) {
      ffMarkerTransit.on('click', (event) => {
        options.click(event);
        this.resetLaneAndNodeStyle();
      });
    }
    if (options.dragstart) {
      ffMarkerTransit.on('dragstart', (event) => {
        options.dragstart(event);
        this.resetLaneAndNodeStyle();
      });
    }
    if (options.dragend) {
      ffMarkerTransit.on('dragend', options.dragend);
    }

    // add to markers group
    this._floorsGroup.addLayer(ffMarkerTransit);

    // add to the index
    // this._floorsTransit[fMarker.id] = ffMarkerTransit;
    this._floorsTransit[fMarker.transitPoint['id']] = ffMarkerTransit;

    return ffMarkerTransit;
  }

  protected createFloorWaitingMarker(
    fMarker: LiftFloor,
    options: Partial<FloorMarkerOptions>
  ): Marker {
    // create new marker
    const fWaitingPoint: PointTuple = [
      fMarker.waitingPoint.x,
      fMarker.waitingPoint.y,
    ];
    options = {
      ...options,
      // markerId: fMarker.id,
      markerId: fMarker.waitingPoint['id'],
      title: fMarker.userLevel,
    };

    // unproject the Point to latlng using zoom level of 1

    const latlngWaiting = this.xYtoLatlng(fWaitingPoint);

    // create the marker and the events

    const ffMarkerWaiting = marker(latlngWaiting, options);
    if (options.click) {
      ffMarkerWaiting.on('click', (event) => {
        options.click(event);
        this.resetLaneAndNodeStyle();
      });
    }
    if (options.dragstart) {
      ffMarkerWaiting.on('dragstart', (event) => {
        options.dragstart(event);
        this.resetLaneAndNodeStyle();
      });
    }
    if (options.dragend) {
      ffMarkerWaiting.on('dragend', options.dragend);
    }

    // add to markers group

    this._floorsGroup.addLayer(ffMarkerWaiting);

    // add to the index

    // this._floorsWaiting[fMarker.id] = ffMarkerWaiting;
    this._floorsWaiting[fMarker.waitingPoint['id']] = ffMarkerWaiting;

    return ffMarkerWaiting;
  }

  protected createFloorExitMarker(
    fMarker: LiftFloor,
    options: Partial<FloorMarkerOptions>
  ): Marker {
    // create new marker
    const fExitPoint: PointTuple = [fMarker.exitPoint.x, fMarker.exitPoint.y];
    options = {
      ...options,
      // markerId: fMarker.id,
      markerId: fMarker.exitPoint['id'],
      title: fMarker.userLevel,
    };

    // unproject the Point to latlng using zoom level of 1

    const latlngExit = this.xYtoLatlng(fExitPoint);

    // create the marker and the events

    const ffMarkerExit = marker(latlngExit, options);
    if (options.click) {
      ffMarkerExit.on('click', (event) => {
        options.click(event);
        this.resetLaneAndNodeStyle();
      });
    }
    if (options.dragstart) {
      ffMarkerExit.on('dragstart', (event) => {
        options.dragstart(event);
        this.resetLaneAndNodeStyle();
      });
    }
    if (options.dragend) {
      ffMarkerExit.on('dragend', options.dragend);
    }
    // add to markers group
    this._floorsGroup.addLayer(ffMarkerExit);
    // add to the index
    this._floorsExit[fMarker.exitPoint['id']] = ffMarkerExit;

    return ffMarkerExit;
  }

  protected createChargingMarker(
    cMarker: ChargingPointMarker,
    options: Partial<RmMarkerOptions>
  ): Marker {
    // create new marker
    const lPoint: PointTuple = [cMarker.position.x, cMarker.position.y];
    options = {
      ...options,
      markerId: cMarker.id,
      title: cMarker.name,
    };

    // unproject the Point to latlng using zoom level of 1
    const latlng = this.xYtoLatlng(lPoint);

    // create the marker and the events
    const cfMarker = marker(latlng, options);
    if (options.click) {
      cfMarker.on('click', (event) => {
        options.click(event);
        this.resetLaneAndNodeStyle();
      });
    }
    if (options.dragstart) {
      cfMarker.on('dragstart', (event) => {
        options.dragstart(event);
        this.resetLaneAndNodeStyle();
      });
    }
    if (options.dragend) {
      cfMarker.on('dragend', options.dragend);
    }

    if (options.drag) {
      cfMarker.on('drag', options.drag);
    }

    // add to markers group
    this._chargersGroup.addLayer(cfMarker);

    // add to the index
    this._chargers[cMarker.id] = cfMarker;

    return cfMarker;
  }

  protected updateMarker2(lfMarker: Marker, lMarker: LayoutMarker): void {
    this.setMarkerIconFromLayoutMarker(lfMarker, lMarker);

    const coordinates: PointExpression = [
      lMarker.position.x,
      lMarker.position.y,
    ];
    const latlng = this.xYtoLatlng(coordinates);
    lfMarker.setLatLng(latlng);
  }

  protected updateSensor2(sfMarker: Marker, sMarker: LayoutSensor): void {
    this.setSensorIcon(sfMarker, 'grey', sMarker.name);

    const coordinates: PointExpression = [
      sMarker.coordinate.x,
      sMarker.coordinate.y,
    ];
    const latlng = this.xYtoLatlng(coordinates);
    sfMarker.setLatLng(latlng);
  }

  protected updateFloorTransit(
    ffMarker: Marker,
    fMarker: LiftFloor,
    type: string
  ): void {
    // this.setFloorIcon(ffMarker, 'red', fMarker.userLevel);

    if (type === MARKER_TYPE.transitPoint) {
      this.setFloorIcon(
        ffMarker,
        'green',
        // fMarker.userLevel,
        fMarker.transitPoint.name,
        'Lift'
      );
      const coordinateTransitPoint: PointExpression = [
        fMarker.transitPoint.x,
        fMarker.transitPoint.y,
      ];
      const latlngTransitPoint = this.xYtoLatlng(coordinateTransitPoint);
      ffMarker.setLatLng(latlngTransitPoint);
    }

    if (type === MARKER_TYPE.waitingPoint) {
      this.setFloorIcon(
        ffMarker,
        'red',
        // fMarker.userLevel,
        fMarker.waitingPoint.name,
        'Waiting Point'
      );
      const coordinateWaitingPoint: PointExpression = [
        fMarker.waitingPoint.x,
        fMarker.waitingPoint.y,
      ];

      const latlngWaitingPoint = this.xYtoLatlng(coordinateWaitingPoint);

      ffMarker.setLatLng(latlngWaitingPoint);
    }
  }
  protected updateFloorWaiting(
    ffMarker: Marker,
    fMarker: LiftFloor,
    type: string
  ): void {
    // this.setFloorIcon(ffMarker, 'red', fMarker.userLevel);

    if (type === MARKER_TYPE.transitPoint) {
      this.setFloorIcon(
        ffMarker,
        'green',
        // fMarker.userLevel,
        fMarker.transitPoint.name,
        'Lift'
      );
      const coordinateTransitPoint: PointExpression = [
        fMarker.transitPoint.x,
        fMarker.transitPoint.y,
      ];
      const latlngTransitPoint = this.xYtoLatlng(coordinateTransitPoint);
      ffMarker.setLatLng(latlngTransitPoint);
    }

    if (type === MARKER_TYPE.waitingPoint) {
      this.setFloorIcon(
        ffMarker,
        'red',
        // fMarker.userLevel,
        fMarker.waitingPoint.name,
        'Waiting Point'
      );
      const coordinateWaitingPoint: PointExpression = [
        fMarker.waitingPoint.x,
        fMarker.waitingPoint.y,
      ];

      const latlngWaitingPoint = this.xYtoLatlng(coordinateWaitingPoint);

      ffMarker.setLatLng(latlngWaitingPoint);
    }
  }

  protected updateChargingMarker(
    cfMarker: Marker,
    cMarker: ChargingPointMarker
  ): void {
    this.setChargerIcon(cfMarker, cMarker.metadata.color, cMarker.name);

    const coordinates: PointExpression = [
      cMarker.position.x,
      cMarker.position.y,
    ];
    const latlng = this.xYtoLatlng(coordinates);
    cfMarker.setLatLng(latlng);
  }

  /**
   * Create a LayoutMarker given a click event
   *
   * @param e
   * @returns
   */
  public createMarkerFromEvent = (
    e: LeafletMouseEvent
  ): Partial<LayoutMarker> => {
    const xy = this.map.project(e.latlng, 1);
    const newMarker = {
      name: 'Untitled Marker',
      color: 'grey',
      capacity: 1,
      position: {
        x: xy.x,
        y: xy.y,
        z: 0,
      },
      angle: 0,
      iconId: '',
      iconUrl: '',
      isDraft: false,
      isNew: true,
      isDeleted: false,
    };

    return newMarker;
  };

  public latlngToXy(latlng: LatLng): Point {
    return this.map.project(latlng, 1);
  }

  public xYtoLatlng(xy: PointExpression): LatLng {
    return this.map.unproject(xy, 1);
  }

  addEventFlag(eventName: string, coordinate: PointExpression) {
    const zoom = this.map.getZoom();

    const eventCoordinate = this.xYtoLatlng(coordinate);

    this.setEventFlag(eventCoordinate);

    // add label
    if (this._options.isShowLabels) {
      this.addEventName(eventCoordinate, eventName);
    }
  }

  addRobot(robotId: string, robotPosition: PointExpression) {
    const zoom = this.map.getZoom();
    const robotCoordinates: PointExpression = [
      robotPosition[0] * zoom,
      robotPosition[1] * zoom,
    ];

    const coordinates = this.map.unproject(robotCoordinates, zoom);
    const robotObject = marker(coordinates, { draggable: false });
    this._robots[robotId] = robotObject;
    this._robotsGroup.addLayer(robotObject);
  }

  addLabel(location: LatLngExpression, label: string): void {
    const labelObject = marker(location, {
      icon: divIcon({
        className: 'custom-marker-pin',
        html: `<div class="marker-label">${label}</div>`,
        iconSize: [80, 60],
        iconAnchor: [-32, 62], // 62=32+30, 30 from half of the height of the icon
      }),
      zIndexOffset: 1000,
    });
    this._labelsGroup.addLayer(labelObject);
  }

  addEventName(location: LatLngExpression, label: string): void {
    const labelObject = marker(location, {
      icon: divIcon({
        className: 'custom-marker-pin',
        html: `<div class="marker-label">${label}</div>`,
        iconSize: [100, 60],
        iconAnchor: [20, 30],
      }),
      zIndexOffset: 1000,
    });
    this._eventNamesGroup.addLayer(labelObject);
  }

  public removeMarker(refId: string): void {
    const selectedMarker = get(this._markers, refId, '');
    if (selectedMarker) {
      this._markersGroup.removeLayer(selectedMarker);
      delete this._markers[refId];
    }
  }
  public removeFloor(refId: string, type: string): void {
    const markers =
      type === MARKER_TYPE.transitPoint
        ? this._floorsTransit
        : type === MARKER_TYPE.waitingPoint
        ? this._floorsWaiting
        : this._floorsExit;

    const selectedFloorMarker = get(markers, refId, '');

    if (selectedFloorMarker && type === MARKER_TYPE.transitPoint) {
      // this._floorsGroup.removeLayer(selectedFloorMarker);
      // delete this._floorsTransit[refId];

      this._floorsGroup.clearLayers();
    }
    if (selectedFloorMarker && type === MARKER_TYPE.waitingPoint) {
      // this._floorsGroup.removeLayer(selectedFloorMarker);
      // delete this._floorsWaiting[refId];

      this._floorsGroup.clearLayers();
    }

    if (selectedFloorMarker && type === MARKER_TYPE.exitPoint) {
      this._floorsGroup.clearLayers();
    }
  }

  /**
   * This is a helper function to update the reference key
   * to access the array of leaflet markers. This can be used
   * when the 'layout marker' id is updated (i.e. upon calling the
   * API to create marker)
   *
   * @param newRefId
   * @param oldRefId
   */
  public updateMarkerReference(newRefId: string, oldRefId: string): void {
    const selectedMarker = get(this._markers, oldRefId, '');
    if (selectedMarker) {
      this._markers[newRefId] = selectedMarker;
      delete this._markers[oldRefId];
    }
  }

  protected setMarkerIcon = (
    selectedMarker: Marker,
    imageUrl?: string,
    color: string = 'grey',
    label?: string,
    metadata?: any
  ): void => {
    const colorArr = ['green', 'yellow', 'dangerRed', 'red'];
    const imgUrlObj = {
      green: 'settings/lift',
      yellow: 'markers/electricity',
      dangerRed: 'markers/verified_user',
      red: 'settings/waiting-point',
    };
    if (colorArr.includes(color) && metadata?.type !== 'marker') {
      imageUrl = `/assets/images/${imgUrlObj[color]}.svg`;
    }
    const htmlIcon = divIcon({
      className: 'custom-marker-pin',
      html: `
        <span class="marker-label">${label ?? ''}</span>
        <div class="marker-pin ${color}">
          <div class="marker-icon ${imageUrl ?? 'hidden'}">
            <img src="${imageUrl}">
          </div>
        </div>
        <div class="marker-shadow"></div>
      `,
      iconSize: [64, 64],
      iconAnchor: [64 / 2, 64],
    });
    selectedMarker.setIcon(htmlIcon);
  };

  protected setSensorIcon = (
    selectedMarker: Marker,
    color: string = 'indigo',
    label?: string
  ): void => {
    const imageUrl: string = '/assets/images/settings/sensors.svg';
    const htmlIcon = divIcon({
      className: 'custom-marker-pin',
      html: `
        <span class="marker-label">${label ?? ''}</span>
        <div class="marker-pin ${color}">
          <div class="marker-icon sensor-icon ${imageUrl ?? 'hidden'}">
            <img src="${imageUrl}">
          </div>
        </div>
        <div class="marker-shadow"></div>
      `,
      iconSize: [64, 64],
      iconAnchor: [64 / 2, 64],
    });
    selectedMarker.setIcon(htmlIcon);
  };

  public setFloorIcon = (
    selectedMarker: Marker,
    color: string = 'indigo',
    label?: string,
    type?: string
  ): void => {
    let imageUrl: string;
    if (type === 'Lift') {
      imageUrl = '/assets/images/settings/lift.svg';
    }

    if (type === 'Waiting Point') {
      imageUrl = '/assets/images/settings/waiting-point-lift.svg';
    }

    if (type === 'Exit Point') {
      imageUrl = '/assets/images/settings/exit-point.svg';
    }

    const htmlIcon = divIcon({
      className: 'custom-marker-pin',
      html: `
        <span class="marker-label floor-label">${label}</span>
        <div class="marker-pin floor-pin ${color}">
          <div class="marker-icon floor-icon${
            type === 'Lift' ? '-lift' : '-mark'
          } ${imageUrl ?? 'hidden'}">
            <img src="${imageUrl}">
          </div>
        </div>
        <div class="marker-shadow"></div>
      `,
      iconSize: [32, 32],
      iconAnchor: [32 / 2, 32],
    });
    selectedMarker.setIcon(htmlIcon);
  };

  public setNodeWaitingPointIcon = (
    selectedMarker: Marker,
    color: string = '#4C9AFF',
    label?: string
  ): void => {
    const imageUrl: string = '/assets/images/map-icon/node-waiting-point.svg';
    const htmlIcon = divIcon({
      className: 'node-waiting-point-marker-pin',
      html: `
        <div class="node-wp-marker-pin ${color}">
          <div class="node-wp-marker-icon ${imageUrl ?? 'hidden'}">
            <img src="${imageUrl}">
          </div>
        </div>
      `,
      iconSize: [22, 22],
      iconAnchor: [22 / 2, 22 / 2],
    });
    selectedMarker.setIcon(htmlIcon);
  };

  setIcon(id: string, iconColor: string, iconImageUrl: string): void {
    const htmlIcon = divIcon({
      className: 'custom-marker-pin',
      html: `
        <div class="marker-pin ${iconColor}">
          <div class="marker-icon">
            <img src="${iconImageUrl}">
          </div>
        </div>
        <div class="marker-shadow"></div>
      `,
      iconSize: [64, 64],
      iconAnchor: [64 / 2, 64],
    });
    const selectedMarker = get(this._markers, id, '');
    if (selectedMarker) {
      selectedMarker.setIcon(htmlIcon);
    }
  }

  protected setChargerIcon = (
    selectedMarker: Marker,
    color: string = 'yellow',
    label?: string
  ): void => {
    const imageUrl: string = '/assets/images/markers/electricity.svg';
    const htmlIcon = divIcon({
      className: 'custom-marker-pin',
      html: `
        <span class="marker-label">${label ?? ''}</span>
        <div class="marker-pin ${color}">
          <div class="marker-icon charging-icon ${imageUrl ?? 'hidden'}">
            <img src="${imageUrl}">
          </div>
        </div>
        <div class="marker-shadow"></div>
      `,
      iconSize: [64, 64],
      iconAnchor: [64 / 2, 64],
    });
    selectedMarker.setIcon(htmlIcon);
  };

  setEventFlag(location: LatLngExpression): void {
    const labelObject = marker(location, {
      icon: divIcon({
        className: 'custom-marker-pin',
        html: `
        <div class="event-flag">
          <img src="/assets/icons/flag_normal.svg">
        </div>
      `,
        iconSize: [25, 25],
        iconAnchor: [8, 12],
      }),
    });

    this._eventsGroup.addLayer(labelObject);
  }

  updateMarker(id: string, data: LayoutMarker): void {
    const selectedMarker = get(this._markers, id, '');
    if (selectedMarker) {
      this.setIcon(id, data.metadata.color, data.marker.icon);
      const coordinates: PointExpression = [data.position.x, data.position.y];
      const latlng = this.xYtoLatlng(coordinates);
      selectedMarker.setLatLng(latlng);
    }
  }

  clear(): void {
    this._markersGroup.clearLayers();
    this._sensorsGroup.clearLayers();
    this._floorsGroup.clearLayers();
    this._eventsGroup.clearLayers();
    this._eventNamesGroup.clearLayers();
    this._liftsGroup.clearLayers();
    this._polylinesGroup.clearLayers();
    this._tooltipsGroup.clearLayers();
    this._chargersGroup.clearLayers();
    this._doorsGroup.clearLayers();
    this._doorLinesGroup.clearLayers();
    this._doorTypeLinesGroup.clearLayers();
    this._zoneNodesGroup.clearLayers();
    this._zonesGroup.clearLayers();
    this._tempZonesGroup.clearLayers();
    this._tempZone = undefined;
    this._tempGraph && this._tempGraph.clearLayers();
    this._tempLane && this._tempLane.clearLayers();
    this._savedGraph && this._savedGraph.clearLayers();
    this._trackingRadiusGroup.clearLayers();
    this._trackingWaitingPointGroup.clearLayers();
    this._fireHoldingGroup.clearLayers();
    this._linkAreaTransitGroup.clearLayers();
  }

  reset(): void {
    this.map.removeLayer(this._gridImage);
    this.map.removeLayer(this._markersGroup);
    this.map.removeLayer(this._sensorsGroup);
    this.map.removeLayer(this._floorsGroup);
    this.map.removeLayer(this._robotsGroup);
    this.map.removeLayer(this._labelsGroup);
    this.map.removeLayer(this._eventsGroup);
    this.map.removeLayer(this._eventNamesGroup);
    this.map.removeLayer(this._liftsGroup);
    this.map.removeLayer(this._polylinesGroup);
    this.map.removeLayer(this._tooltipsGroup);
    this.map.removeLayer(this._chargersGroup);
    this.map.removeLayer(this._doorsGroup);
    this.map.removeLayer(this._doorLinesGroup);
    this.map.removeLayer(this._doorTypeLinesGroup);
    this.map.removeLayer(this._zoneNodesGroup);
    this.map.removeLayer(this._zonesGroup);
    this.map.removeLayer(this._tempGraph);
    if (this._tempLane) {
      this.map.removeLayer(this._tempLane);
    }
    this.map.removeLayer(this._savedGraph);
    this.map.removeLayer(this._tempZonesGroup);
    this.map.removeLayer(this._trackingRadiusGroup);
    this.map.removeLayer(this._trackingWaitingPointGroup);
    this.map.removeLayer(this._fireHoldingGroup);
    this.map.removeLayer(this._linkAreaTransitGroup);
    this._tempZone = undefined;
    this._markers = {};
    this._robots = {};
    this._lifts = {};
    this._polylines = {};
    this._tooltips = {};
    this._chargers = {};
    this._doors = {};
    this._doorLines = {};
    this._doorTypeLines = {};
    this._zoneNodes = {};
    this._zones = {};
    this.allLanes = {};
    this.allMetaLanes = {};
    this.allNodes = {};
    this.savedNodes = {};
    this.map = null;
    this._options = {};
    this._gridImage = null;
    this._trackingRadius = {};
    this._trackingWaitingPoints = {};
    this._fireHoldingNodes = {};
    this._linkAreaTransitNodes = {};
  }

  resetMarkerGroup(): void {
    this._markersGroup = layerGroup();
    this.map.addLayer(this._markersGroup);
  }

  resetSensorGroup(): void {
    this._sensorsGroup = layerGroup();
    this.map.addLayer(this._sensorsGroup);
  }
  resetFloorGroup(): void {
    this._floorsGroup = layerGroup();
    this.map.addLayer(this._floorsGroup);
  }

  parseCoordinateToAxis(position: LatLngExpression, zoomLevel: number): Point {
    const valueConvert = this.map.project(position, zoomLevel);
    return valueConvert;
  }

  isValidCoordinate(position: LatLngExpression): boolean {
    return this.getImageBounds().contains(position);
  }

  setShowMarkers(showMarkers: boolean): void {
    if (showMarkers) {
      this.map.addLayer(this._markersGroup);
    } else {
      this.map.removeLayer(this._markersGroup);
    }
    this.setShowLabels(showMarkers);
  }

  setShowSensors(showSensors: boolean): void {
    if (showSensors) {
      this.map.addLayer(this._sensorsGroup);
    } else {
      this.map.removeLayer(this._sensorsGroup);
    }
  }

  setRobotLocation(id: string, coordinates: PointExpression): void {
    const position = this.map.unproject(coordinates, this.map.getZoom());
    const selectedRobot = get(this._robots, id, '');
    // TODO: dynamic duration based on distance
    const duration = 5000;
    if (selectedRobot) {
      // the package leaflet.marker.slideto doesn't have type definition, so using ts-ignore for now
      // @ts-ignore
      selectedRobot.slideTo(position, { duration });
    }
  }

  setShowRobots(showRobots: boolean): void {
    if (showRobots) {
      this.map.addLayer(this._robotsGroup);
    } else {
      this.map.removeLayer(this._robotsGroup);
    }
  }

  setShowLabels(showLabels: boolean): void {
    if (showLabels) {
      this.map.addLayer(this._labelsGroup);
    } else {
      this.map.removeLayer(this._labelsGroup);
    }
  }

  getCoordinate(position: LatLngExpression): Point {
    return this.map.project(position);
  }

  public renderLiftMarker(
    liftMarker: PointMarker,
    options: Partial<LiftMarkerOptions>
  ): void {
    // Create. Then, set the icon and color
    const fMarker = this.createLiftMarker(liftMarker, options);
    const label = `<span class="lift-marker-label">${
      options.label ?? ''
    }</span>`;
    this.setLiftIcon(
      fMarker,
      options.color,
      label,
      options.iconId,
      options.iconClass
    );
  }

  protected createLiftMarker(
    liftMarker: PointMarker,
    options: Partial<LiftMarkerOptions>
  ): Marker {
    // create new marker
    const liftPoint: PointTuple = [liftMarker.x, liftMarker.y];
    options = {
      ...options,
      markerId: liftMarker.id,
    };

    // unproject the Point to latlng using zoom level of 1
    const latlng = this.xYtoLatlng(liftPoint);

    // create the marker and the events
    const fMarker = marker(latlng, options);
    if (options.click) {
      fMarker.on('click', (event) => {
        options.click(event);
        this.resetLaneAndNodeStyle();
      });
    }
    if (options.dragstart) {
      fMarker.on('dragstart', (event) => {
        options.dragstart(event);
        this.resetLaneAndNodeStyle();
      });
    }
    if (options.dragend) {
      fMarker.on('dragend', options.dragend);
    }

    if (options.drag) {
      fMarker.on('drag', options.drag);
    }

    // add to markers group
    this._liftsGroup.addLayer(fMarker);

    // add to the index
    this._lifts[liftMarker.id] = fMarker;

    return fMarker;
  }

  protected setLiftIcon = (
    selectedMarker: Marker,
    color: string = 'indigo',
    label: string,
    iconName: string,
    iconClass: string
  ): void => {
    if (iconName === 'waiting-point-lift.svg') {
      iconClass = 'waiting-lift';
    }
    const imageUrl = `/assets/images/settings/${iconName}`;
    const htmlIcon = divIcon({
      className: 'custom-marker-pin',
      html: `
        ${label}
        <div class="marker-pin ${color}">
          <div class="marker-icon lift-icon-${iconClass} ${
        imageUrl ?? 'hidden'
      }">
            <img src="${imageUrl}">
          </div>
        </div>
        <div class="marker-shadow"></div>
      `,
      iconSize: [64, 64],
      iconAnchor: [64 / 2, 64],
    });
    selectedMarker.setIcon(htmlIcon);
  };

  public removeLiftMarker(refId: string): void {
    const selectedMarker = get(this._lifts, refId, '');
    if (selectedMarker) {
      this._liftsGroup.removeLayer(selectedMarker);
      delete this._lifts[refId];
    }
  }

  public resetLiftGroup(): void {
    this._liftsGroup = layerGroup();
    this.map.addLayer(this._liftsGroup);
  }

  hideLiftFlag: boolean = false;
  public setShowLifts(showLifts: boolean): void {
    if (showLifts) {
      this.hideLiftFlag = false;
      this.map.addLayer(this._liftsGroup);
      this.setShowLiftsLine();
      // this.map.addLayer(this._polylinesGroup);
    } else {
      this.hideLiftFlag = true;
      this.map.removeLayer(this._liftsGroup);
      this.map.removeLayer(this._polylinesGroup);
      this.setHideLiftsLine();
    }
  }

  tempHiddenGraph = [];
  setHideLiftsLine() {
    this.tempHiddenGraph = [];
    for (const tempLine of this._drawnLanes) {
      if (tempLine.fromNode.lift && tempLine.toNode.lift) {
        this.tempHiddenGraph.push(tempLine);
        this._savedGraph.removeLayer(tempLine.lane);
        this._tempGraph.removeLayer(tempLine.lane);
      }
    }
  }

  setShowLiftsLine() {
    for (const layer of this.tempHiddenGraph) {
      if(!this._drawnLanes.find(item => item.lane.options['markerId']  === layer.lane.options['markerId'] )) {
        this._drawnLanes.push(layer)
      }
      this.updateLane(layer.fromNode, this.onTrackingLaneClick.bind(this));
      this.updateLane(layer.toNode, this.onTrackingLaneClick.bind(this));

      // this._savedGraph.addLayer(layer.lane);
    }
    this.tempHiddenGraph = [];
  }

  private onTrackingLaneClick(e: LeafletEvent): void {
    const refId = get(e, 'target.options.markerId', '') as string;
    const nodeIds = refId.split('|;|');
    const fromNode = this.savedNodes[nodeIds[0]];
    const toNode = this.savedNodes[nodeIds[1]];
    this.layoutsService.togglePopupBar.next({
      type: 'laneDetails',
      fromNode,
      toNode,
    });
  }

  public removeChargingMarker(refId: string): void {
    const selectedMarker = get(this._chargers, refId, '');
    if (selectedMarker) {
      this._chargersGroup.removeLayer(selectedMarker);
      delete this._chargers[refId];
    }
  }

  public setShowChargers(showChargers: boolean): void {
    if (showChargers) {
      this.map.addLayer(this._chargersGroup);
    } else {
      this.map.removeLayer(this._chargersGroup);
    }
  }

  public renderWPCharging(
    liftMarker: PointMarker,
    options: Partial<LiftMarkerOptions>
  ): void {
    // Create. Then, set the icon and color
    const fMarker = this.createWPCharging(liftMarker, options);
    const label = `<span class="lift-marker-label">${
      options.label ?? ''
    }</span>`;
    this.setLiftIcon(
      fMarker,
      options.color,
      label,
      options.iconId,
      options.iconClass
    );
  }

  private createWPCharging(
    chargerMarker: PointMarker,
    options: Partial<LiftMarkerOptions>
  ): Marker {
    // create new marker
    const liftPoint: PointTuple = [chargerMarker.x, chargerMarker.y];
    options = {
      ...options,
      markerId: chargerMarker.id,
    };

    // unproject the Point to latlng using zoom level of 1
    const latlng = this.xYtoLatlng(liftPoint);

    // create the marker and the events
    const fMarker = marker(latlng, options);
    if (options.click) {
      fMarker.on('click', (event) => {
        options.click(event);
        this.resetLaneAndNodeStyle();
      });
    }
    if (options.dragstart) {
      fMarker.on('dragstart', (event) => {
        options.dragstart(event);
        this.resetLaneAndNodeStyle();
      });
    }
    if (options.dragend) {
      fMarker.on('dragend', options.dragend);
    }

    // add to markers group
    this._chargersGroup.addLayer(fMarker);

    // add to the index
    this._chargers[chargerMarker.id] = fMarker;

    return fMarker;
  }

  public removeWPCharger(refId: string): void {
    const selectedMarker = get(this._chargers, refId, '');
    if (selectedMarker) {
      this._chargersGroup.removeLayer(selectedMarker);
      delete this._chargers[refId];
    }
  }

  public setShowDoors(showDoors: boolean): void {
    if (showDoors) {
      this.map.addLayer(this._doorsGroup);
      this.map.addLayer(this._doorLinesGroup);
      this.map.addLayer(this._doorTypeLinesGroup);
    } else {
      this.map.removeLayer(this._doorsGroup);
      this.map.removeLayer(this._doorLinesGroup);
      this.map.removeLayer(this._doorTypeLinesGroup);
    }
  }

  public renderDoorMarker(
    doorMarker: PointMarker,
    options: Partial<RmMarkerOptions>
  ): void {
    // Create. Then, set the icon and color
    const dMarker = this.createDoorMarker(doorMarker, options);
    this.setDoorIcon(dMarker, options.color);
  }

  protected createDoorMarker(
    doorMarker: PointMarker,
    options: Partial<RmMarkerOptions>
  ): Marker {
    // create new marker
    const doorPoint: PointTuple = [doorMarker.x, doorMarker.y];
    options = {
      ...options,
      markerId: doorMarker.id,
    };

    // unproject the Point to latlng using zoom level of 1
    const latlng = this.xYtoLatlng(doorPoint);

    // create the marker and the events
    const dMarker = marker(latlng, options);
    if (options.click) {
      dMarker.on('click', (event) => {
        options.click(event);
        this.resetLaneAndNodeStyle();
      });
    }
    if (options.dragstart) {
      dMarker.on('dragstart', (event) => {
        options.dragstart(event);
        this.resetLaneAndNodeStyle();
      });
    }
    if (options.dragend) {
      dMarker.on('dragend', options.dragend);
    }

    // add to markers group
    this._doorsGroup.addLayer(dMarker);

    // add to the index
    this._doors[doorMarker.id] = dMarker;

    return dMarker;
  }

  protected setDoorIcon = (selectedMarker: Marker, color: string): void => {
    const htmlIcon = divIcon({
      className: 'custom-marker-pin',
      html: `
        <div class="marker-circle ${color}">
        </div>
      `,
      iconSize: [14, 14],
      iconAnchor: [14, 14],
    });
    selectedMarker.setIcon(htmlIcon);
  };

  public removeDoorMarker(refId: string): void {
    const selectedMarker = get(this._doors, refId, '');
    if (selectedMarker) {
      this._doorsGroup.removeLayer(selectedMarker);
      delete this._doors[refId];
    }
  }

  public resetDoorGroup(): void {
    this._doorsGroup = layerGroup();
    this.map.addLayer(this._doorsGroup);
  }

  /**
   * Render line between two link area point
   *
   * @param startPoint - Start point of the line
   * @param endPoint - End point of the line
   * @param refId - Reference ID use by leaflet
   * @param type - Type of the connecting point, can be 'door' | 'lift' | 'door-type'
   * @param options - Line options
   */
  public renderLineMarker(
    startPoint: PointMarker,
    endPoint: PointMarker,
    refId: string,
    type: 'door' | 'lift' | 'door-type',
    options: Partial<RmPolylineOptions>
  ): void {
    // create line for each point
    const startxy: PointTuple = [startPoint.x, startPoint.y];
    const endxy: PointTuple = [endPoint.x, endPoint.y];

    // unproject the Point to latlng using zoom level of 1
    const startlatlng = this.xYtoLatlng(startxy);
    const endlatlng = this.xYtoLatlng(endxy);
    // create line between two point
    const line = polyline([startlatlng, endlatlng], options);
    // add to lines group and line index, depend on line type
    if (type === 'lift') {
      // this._polylinesGroup.addLayer(line);
      // this.map.addLayer(this._polylinesGroup);
      // this._polylines[refId] = line;
    } else if (type === 'door') {
      this._doorLinesGroup.addLayer(line);

      //add tooltip for the door name
      line.bindTooltip(options.name, {
        permanent: true,
        opacity: 1,
        direction: 'center',
        className: 'door-marker-label',
      });

      this._doorLines[refId] = line;
    } else if (type === 'door-type') {
      this._doorTypeLinesGroup.addLayer(line);
      this.map.addLayer(this._doorTypeLinesGroup);

      this._doorTypeLines[refId] = line;
    }
  }

  public removeLineMarker(refId: string, type: 'door' | 'lift'): void {
    const lines = type === 'lift' ? this._polylines : this._doorLines;
    const linesGroup =
      type === 'lift' ? this._polylinesGroup : this._doorLinesGroup;
    const selectedMarker = get(lines, refId, '');
    if (selectedMarker) {
      linesGroup.removeLayer(selectedMarker);
      delete lines[refId];
    }
  }

  public removeDoorTypeLineMarker(refId: string, totalLines: number): void {
    for (let i = 1; i <= totalLines; i++) {
      const id = `${refId}_${i}`;
      const selectedMarker = get(this._doorTypeLines, id, '');
      if (selectedMarker) {
        this._doorTypeLinesGroup.removeLayer(selectedMarker);
        delete this._doorTypeLines[id];
      }
    }
  }

  public removeTooltipMarker(refId: string): void {
    const selectedMarker = get(this._tooltips, refId, '');
    if (selectedMarker) {
      this._tooltipsGroup.removeLayer(selectedMarker);
      delete this._tooltips[refId];
    }
  }

  public resetLineGroup(type: 'lift' | 'door'): void {
    if (type === 'lift') {
      // this._polylinesGroup = layerGroup();
      // this.map.addLayer(this._polylinesGroup);
    } else if (type === 'door') {
      this._doorLinesGroup = layerGroup();
      this.map.addLayer(this._doorLinesGroup);
      this._doorTypeLinesGroup = layerGroup();
      this.map.addLayer(this._doorTypeLinesGroup);
    }
  }

  public resetTooltipGroup(): void {
    this._tooltipsGroup = layerGroup();
    this.map.addLayer(this._tooltipsGroup);
  }

  public updateMarkerLatLng(lMarker: PointMarker, type: string): void {
    let markers = this._markers;
    switch (type) {
      case MARKER_TYPE.marker:
        markers = this._markers;
        break;
      case MARKER_TYPE.lift:
        markers = this._lifts;
        break;
      case MARKER_TYPE.sensor:
        markers = this._sensors;
        break;
      case MARKER_TYPE.transitPoint:
        markers = this._floorsTransit;
        break;
      case MARKER_TYPE.waitingPoint:
        markers = this._floorsWaiting;
        break;
      case MARKER_TYPE.exitPoint:
        markers = this._floorsExit;
        break;
      case MARKER_TYPE.charging:
        markers = this._chargers;
        break;
      case MARKER_TYPE.door:
        markers = this._doors;
        break;
      case MARKER_TYPE.zoneNode:
        markers = this._zoneNodes;
        break;
      case MARKER_TYPE.trafficGraph:
        markers = this.allNodes;
        break;
    }
    const selectedMarker = get(markers, lMarker.id, '');
    if (selectedMarker) {
      const coordinates: PointExpression = [lMarker.x, lMarker.y];
      const latlng = this.xYtoLatlng(coordinates);
      selectedMarker.setLatLng(latlng);
    }
  }

  public setPanPoint(position: PointTuple, zoomNumber: number): void {
    const latLng = this.xYtoLatlng(position);
    this.map.flyTo(latLng, zoomNumber);
  }

  public openTooltip(position: PointTuple, options?: RmTooltipOptions): void {
    const latlng = this.xYtoLatlng(position);
    const content = `<div>
        <p>X: ${position[0]}</p>
        <p>Y: ${position[1]}</p>
      </div>`;
    this.map.openTooltip(content, latlng, {
      ...options,
      offset: [25, -25],
    });
  }

  public closeTooltip(): void {
    this.map.eachLayer((layer: LayerTooltip) => {
      if (layer?.options?.type === 'tooltip') {
        layer.removeFrom(this.map);
      }
    });
  }

  public getZoom(): number {
    return this.map.getZoom();
  }

  public resetGraph(): void {
    this._templastNode = undefined;
    this._tempGraph && this.map.removeLayer(this._tempGraph);
    this._tempLane && this.map.removeLayer(this._tempLane);
    this._savedGraph && this.map.removeLayer(this._savedGraph);

    this._tempGraph = layerGroup();
    this._tempLane = layerGroup();
    this._savedGraph = layerGroup();

    this.map.addLayer(this._tempGraph);
    this.map.addLayer(this._tempLane);
    this.map.addLayer(this._savedGraph);

    this.allLanes = {};
    this.allMetaLanes = {};
    this.allNodes = {};
    this.savedNodes = {};
  }

  renderGraphNode(
    graphNode: IGraphNode,
    options,
    dataFromApi: boolean,
    callback: (node: IGraphNode, lastNode: IGraphNode) => void
  ) {
    // create a new node
    this.setNodeMarker(graphNode, options, dataFromApi);
    if (graphNode.radiusInMeter) {
      this.renderTrackingRadius(graphNode, {
        radius: graphNode.radiusInMeter,
        nodeId: graphNode.id,
      });
    }
    if (graphNode.waitingPoints && graphNode.waitingPoints.length > 0) {
      graphNode.waitingPoints.map((wp) => {
        // need to set id for each waiting point if it doesnt have id
        const defaultId: string = uuidv4();
        const wpId = wp.id ? wp.id : defaultId;
        // generate waiting point name
        // by using 5 first characters from wp.id
        // the format is wp + . +  5 first characters
        let generatedName = '';
        if (wp.name) {
          generatedName = wp.name;
        } else {
          generatedName = !wp.id
            ? `wp.${defaultId.substring(0, 5)}`
            : `wp.${wp.id.substring(0, 5)}`;
        }

        this.renderTrackingWaitingPoint(
          {
            id: wpId,
            name: generatedName,
            x: wp.x,
            y: wp.y,
          },
          {
            icon: new Icon({
              iconUrl: '/assets/images/map-icon/node-waiting-point.svg',
            }),
            draggable: true,
            label: generatedName,
            title: generatedName,
            nodeId: graphNode.id,
            markerId: wpId,
            dragstart: (e) => {
              options.waitingPointDragStart(e);
            },
            dragend: (e) => {
              options.waitingPointDragEnd(e);
            },
            drag: (e) => {
              options.waitingPointDrag(e);
            },
          }
        );
      });
    }
    //draw lanes for new graph drawing
    if (this._templastNode && !dataFromApi) {
      callback(this._templastNode, graphNode);
    }
    this._templastNode = graphNode;
  }

  /**
   * Set node circle marker
   *
   * @param graphNode
   * @param options
   */
  public setNodeMarker(
    graphNode: IGraphNode,
    options,
    dataFromApi: boolean
  ): void {
    const latlng = this.xYtoLatlng([graphNode.x, graphNode.y]);
    const liftGraphMarkDisplayClass =
      graphNode.lift && graphNode.lift['type'] ? 'lift-graph-mark-display' : '';
    const nodeIcon = icon({
      iconUrl: 'assets/images/markers/marker-blue.svg',
      iconRetinaUrl: 'assets/images/markers/marker-blue-2x.svg',
      iconSize: [30, 30],
      iconAnchor: [14, 29],
      shadowSize: [0, 0],
      className: liftGraphMarkDisplayClass,
    });

    const node = marker(
      {
        lat: latlng.lat,
        lng: latlng.lng,
      },
      { ...options, icon: nodeIcon }
    ).bindTooltip(graphNode.name, {
      permanent: true,
      direction: 'top',
      className: 'transparent-tooltip ' + liftGraphMarkDisplayClass,
      offset: [0, -29],
    });

    const currentNode = graphNode;
    node.on('click', (event) => {
      options.click(event);
      this.resetLaneAndNodeStyle();
      this.markNodesAsSelected(currentNode);
    });
    node.on('dblclick', (event) => {
      options.dblclick(event);
      this.resetLaneAndNodeStyle();
    });

    if (options.dragstart) {
      node.on('dragstart', options.dragend);
    }
    if (options.dragend) {
      node.on('dragend', options.dragend);
    }
    if (options.drag) {
      node.on('drag', options.drag);
    }

    this.allNodes[graphNode.id] = node;
    this.savedNodes[graphNode.id] = graphNode;
    if (dataFromApi) {
      this._savedGraph.addLayer(node);
      this.map.addLayer(this._savedGraph);
    } else {
      this._tempGraph.addLayer(node);
      this.map.addLayer(this._tempGraph);
    }
  }

  /**
   * Draw lanes on the traffic graph while creating the traffic graph
   *
   * @param flagApi to indicate the data from API or just user draw data
   */
  public drawLines(
    fromNode,
    toNode,
    lineOptions: Partial<RmPolylineOptions>,
    isSingleLane: boolean,
    flagApi?: boolean
  ): void {
    if (fromNode === undefined || toNode === undefined) {
      return;
    }

    const latLngFrom = this.xYtoLatlng([fromNode.x, fromNode.y]);
    const latLngTo = this.xYtoLatlng([toNode.x, toNode.y]);
    const laneColor = isSingleLane ? '#F7DDB1' : '#04b4cd';
    const dashLane = toNode.type !== 'point' ? '10, 10' : '0, 0';
    const lane = polyline(
      [
        { lat: latLngFrom.lat, lng: latLngFrom.lng },
        { lat: latLngTo.lat, lng: latLngTo.lng },
      ],
      {
        ...lineOptions,
        color: laneColor,
        dashArray: dashLane,
        weight: toNode.type !== 'point' ? 3 : 5,
        opacity: 0.5,
      }
    );

    lane.on('click', (e) => {
      lane.setStyle({ color: 'red' });
      this.resetLaneAndNodeStyle(lane);
      this.markNodesAsSelectedFromLane(lane);
      this._activeLane = lane;
      this._actioveNodes = [
        `${fromNode.id}|;|${toNode.id}`,
        `${toNode.id}|;|${fromNode.id}`,
      ];
    });

    lane.addTo(this.map).bringToBack();

    if (lineOptions.click) {
      lane.on('click', lineOptions.click);
    }

    if (flagApi) {
      this._savedGraph.addLayer(lane);
      this._drawnLanes.push({ fromNode: fromNode, toNode: toNode, lane: lane });
      this.map.addLayer(this._savedGraph);
    } else {
      this._tempGraph.addLayer(lane);
      this._drawnLanes.push({ fromNode: fromNode, toNode: toNode, lane: lane });
      this.map.addLayer(this._tempGraph);
    }

    if (this._activeLane.options) {
      if (this._actioveNodes.includes(lane.options['markerId'])) {
        this._activeLane = lane;
        lane.setStyle({ color: 'red' });
      } else {
        this._activeLane.setStyle({ color: 'red' });
      }
    }
  }

  private markNodesAsSelectedFromLane(selectedLane?: Polyline) {
    const markMarkerIcon = (markMarker: Marker) => {
      const markerOptions = markMarker.options as RmPolylineOptions;
      let liftGraphMarkDisplayClass = '';
      const liftGraphNode = this.savedNodes[markerOptions.markerId];
      if (liftGraphNode.lift && liftGraphNode.lift['type']) {
        liftGraphMarkDisplayClass = 'lift-graph-mark-display';
      }
      const redMarker = Icon.extend({
        options: {
          iconUrl: 'assets/images/markers/marker-red.svg',
          iconRetinaUrl: 'assets/images/markers/marker-red.svg',
          className: liftGraphMarkDisplayClass,
          iconSize: [30, 30],
          iconAnchor: [14, 29],
          shadowSize: [0, 0],
        },
      });
      markMarker.setIcon(new redMarker());
    };

    if (selectedLane) {
      const laneOptions = selectedLane.options as RmPolylineOptions;
      const nodeIds = laneOptions.markerId.split('|;|');

      // Mark nodes in _savedGraph
      this._savedGraph.eachLayer((graphLayer) => {
        if (graphLayer instanceof Marker) {
          const graphOptions = graphLayer.options as RmMarkerOptions;
          if (nodeIds.includes(graphOptions.markerId)) {
            markMarkerIcon(graphLayer);
          }
        }
      });

      // Mark nodes in _tempGraph
      this._tempGraph.eachLayer((graphLayer) => {
        if (graphLayer instanceof Marker) {
          const graphOptions = graphLayer.options as RmMarkerOptions;
          if (nodeIds.includes(graphOptions.markerId)) {
            markMarkerIcon(graphLayer);
          }
        }
      });
    }
  }

  private markNodesAsSelected(selectedNode: IGraphNode) {
    const markMarkerIcon = (markMarker: Marker) => {
      const markerOptions = markMarker.options as RmPolylineOptions;
      let liftGraphMarkDisplayClass = '';
      const liftGraphNode = this.savedNodes[markerOptions.markerId];
      if (liftGraphNode.lift && liftGraphNode.lift['type']) {
        liftGraphMarkDisplayClass = 'lift-graph-mark-display';
      }
      const redMarker = Icon.extend({
        options: {
          iconUrl: 'assets/images/markers/marker-red.svg',
          iconRetinaUrl: 'assets/images/markers/marker-red.svg',
          className: liftGraphMarkDisplayClass,
          iconSize: [30, 30],
          iconAnchor: [14, 29],
          shadowSize: [0, 0],
        },
      });
      markMarker.setIcon(new redMarker());
    };

    if (selectedNode) {
      // Mark nodes in _savedGraph
      this._savedGraph.eachLayer((graphLayer) => {
        if (graphLayer instanceof Marker) {
          const graphOptions = graphLayer.options as RmMarkerOptions;
          if (selectedNode.id === graphOptions.markerId) {
            markMarkerIcon(graphLayer);
          }
        }
      });

      // Mark nodes in _tempGraph
      this._tempGraph.eachLayer((graphLayer) => {
        if (graphLayer instanceof Marker) {
          const graphOptions = graphLayer.options as RmMarkerOptions;
          if (selectedNode.id === graphOptions.markerId) {
            markMarkerIcon(graphLayer);
          }
        }
      });
    }
  }

  public resetLaneAndNodeStyle(selectedLane?: Polyline): void {
    const resetColor = (options: RmPolylineOptions) =>
      options.isSingleLane && options.isSingleLane === true
        ? '#F7DDB1'
        : '#04b4cd';

    const resetMarkerIcon = (resetMarker: Marker) => {
      const markerOptions = resetMarker.options as RmPolylineOptions;
      let liftGraphMarkDisplayClass = '';
      const liftGraphNode = this.savedNodes[markerOptions.markerId];
      if (liftGraphNode.lift && liftGraphNode.lift['type']) {
        liftGraphMarkDisplayClass = 'lift-graph-mark-display';
      }
      resetMarker.setIcon(
        new Icon({
          iconUrl: 'assets/images/markers/marker-blue.svg',
          iconRetinaUrl: 'assets/images/markers/marker-blue-2x.svg',
          iconSize: [30, 30],
          iconAnchor: [14, 29],
          shadowSize: [0, 0],
          className: liftGraphMarkDisplayClass,
        })
      );
    };

    const processLayer = (layer: Polyline) => {
      const laneOptions = layer.options as RmPolylineOptions;
      const resetColorValue = resetColor(laneOptions);
      layer.setStyle({ color: resetColorValue });

      const nodeIds = laneOptions.markerId.split('|;|');
      nodeIds.forEach((nodeId) => {
        this._savedGraph.eachLayer((graphLayer) => {
          if (graphLayer instanceof Marker) {
            const graphOptions = graphLayer.options as RmPolylineOptions;
            if (graphOptions.markerId === nodeId) {
              resetMarkerIcon(graphLayer);
            }
          }
        });

        this._tempGraph.eachLayer((graphLayer) => {
          if (graphLayer instanceof Marker) {
            const graphOptions = graphLayer.options as RmPolylineOptions;
            if (graphOptions.markerId === nodeId) {
              resetMarkerIcon(graphLayer);
            }
          }
        });
      });
    };

    const resetStyle = (layer: Polyline) => {
      if (selectedLane && layer === selectedLane) {
        return;
      }
      processLayer(layer);
    };

    const applyResetStyle = (graph: L.LayerGroup<Polyline | Marker>) => {
      graph.eachLayer((layer) => {
        if (layer instanceof Polyline) {
          resetStyle(layer);
        }
      });
    };

    applyResetStyle(this._savedGraph);
    applyResetStyle(this._tempGraph);
  }

  /***
   * Draw dashed lane to connect gateway with charging point
   * @param node node coordinates
   * @param chargingPoint charging point coordinates
   */
  connectChargertoGateway(node, chargingPoint) {
    const nodeLatlng = this.xYtoLatlng([node.x, node.y]); // node coordinates
    const chargingPointLatlng = this.xYtoLatlng([
      chargingPoint.x,
      chargingPoint.y,
    ]); // selected charging point coordinates

    const dasheLane = polyline(
      [
        { lat: nodeLatlng.lat, lng: nodeLatlng.lng },
        { lat: chargingPointLatlng.lat, lng: chargingPointLatlng.lng },
      ],
      {
        color: '#04b4cd',
        weight: 5,
        opacity: 0.5,
        dashArray: '20, 20',
        dashOffset: '0',
      }
    );

    dasheLane.addTo(this.map).bringToBack();
  }

  /**
   * Draw temperory line
   *
   * @param e mouse hover event
   */
  public drawTempLane(e): void {
    if (!this._templastNode) {
      return;
    }

    if (this._tempLane) {
      this.removeTempLane();
    }
    const latlng = this.xYtoLatlng([
      this._templastNode.x,
      this._templastNode.y,
    ]);
    this._tempLane = polyline(
      [
        {
          lat: latlng.lat,
          lng: latlng.lng,
        },
        e.latlng,
      ],
      {
        color: '#04b4cd',
        weight: 3,
        opacity: 0.5,
        isSingleLane: false,
      } as RmPolylineOptions
    );

    this._tempLane.on('click', (event) => {
      this._tempLane.setStyle({ color: 'red' });
      this.resetLaneAndNodeStyle(this._tempLane);
    });

    this._tempLane.addTo(this.map).bringToBack();
  }

  public removeTempLane(): void {
    if (this._tempLane) {
      if (this.map) {
        this.map.removeLayer(this._tempLane);
      }
      this._tempLane = layerGroup();
    }
  }

  setupLiftpopup(
    marker: Marker,
    type: string,
    node: IGraphNode,
    callback: (e: Marker, type: string) => void,
    callback2: (markerId: string, type: string) => void,
    lift?
  ): void {
    // const marker = e;
    const liftMarkerId = marker['options']['markerId'];
    let popUpName = '';
    if (type === 'waiting' && lift?.waitingPoint) {
      popUpName = lift.waitingPoint.name;
    }
    if (type === 'exit' && lift?.exitPoint) {
      popUpName = lift.exitPoint.name;
    }
    const popup = `<div class="flex flex-col leaflet-popup-content" >
      <button class="close-popup absolute
      right-2 top-2" id="close-popup_${liftMarkerId}" >
        <svg class="w-4 h-4 text-white " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </button>
      <div class="text-center">
        <div class="text-lg text-white font-wrap-marker">${popUpName}</div>
      </div>
      <div class="text-center">
        <div class="text-lg text-white"></div>
      </div>
      <button class="bg-white hover:bg-gray-50 text-black py-2 px-0 rounded my-1 mx-0" id="continue-draw_${liftMarkerId}">
      ${this.translocoService.translate('Connect to graph')}
      </button>
      <button class=" text-white py-2 px-0 rounded my-1 mx-0 " "
      id="lift_marker_${liftMarkerId}"
      style="
        background-color: #5243aa !important;"
      >
        ${this.translocoService.translate('Node Details')}
      </button>
      </div>`;

    marker.unbindPopup();
    this.map.closePopup();
    marker.bindPopup(popup, {
      className: 'transparent-popup',
      keepInView: true,
      minWidth: 220,
      closeButton: false,
      autoClose: true,
      closeOnClick: false,
    });

    marker.on('popupopen', () => {
      const popupContent = document.querySelector('.leaflet-popup-content');
      if (!popupContent) {
        return;
      }

      const continueDraw = document.querySelector(
        `#continue-draw_${liftMarkerId}`
      );
      continueDraw?.addEventListener('click', () => {
        this._templastNode = node;
        callback2(liftMarkerId, type);
        if(node) {
          this.deleteliftMarkerAndGraphLane(node);
        }
        this.resetLaneAndNodeStyle();
        this.map.closePopup();
      });

      const nodeDetails = document.querySelector(
        `#lift_marker_${liftMarkerId}`
      );
      nodeDetails?.addEventListener('click', () => {
        callback(marker, type);
        this.map.closePopup();
      });

      //override popup close button leaflet because close button on leaflet is not working properly (it adds #close to the URL) in the leaflet v1.7.1. (Leaflet bug issue)
      const closePopup = document.querySelector(`#close-popup_${liftMarkerId}`);
      closePopup?.addEventListener('click', () => {
        this.resetLaneAndNodeStyle();
        this.map.closePopup();
      });
    });
  }

  /**
   * delete the lane between waiting/exit point and graph node before reconnect to graph.
   * @param node: IGraphNode - Selected lift marker node
   **/
  public deleteliftMarkerAndGraphLane(node: IGraphNode) {
    const allLane = this.allLanes[node.id] ? this.allLanes[node.id] : [];
    for (const laneId of allLane) {
      if (!this.savedNodes[laneId].lift) {
        // laneId is the conneted normal graph node.
        // delete lane from lift point to graph
        this.allLanes[node.id] = this.allLanes[node.id]?.filter(
          (item) => item !== laneId
        );
        const laneIndex = this._drawnLanes.findIndex(
          (item) => item.lane.options.markerId === `${node.id}|;|${laneId}`
        );
        if (laneIndex !== -1) {
          this._tempGraph.removeLayer(this._drawnLanes[laneIndex].lane);
          this._savedGraph.removeLayer(this._drawnLanes[laneIndex].lane);
          this._drawnLanes.splice(laneIndex, 1);
        }
        //delete lane from graph node to lift point.
        this.allLanes[laneId] = this.allLanes[laneId]?.filter(
          (item) => item !== node.id
        );
        const laneIndex1 = this._drawnLanes.findIndex(
          (item) => item.lane.options.markerId === `${laneId}|;|${node.id}`
        );
        if (laneIndex1 !== -1) {
          this._tempGraph.removeLayer(this._drawnLanes[laneIndex1].lane);
          this._savedGraph.removeLayer(this._drawnLanes[laneIndex1].lane);
          this._drawnLanes.splice(laneIndex1, 1);
        }
        if (this.allLanes[laneId].length === 0) {
          delete this.allLanes[laneId];
        }
      }
    }
    if (this.allLanes[node.id]?.length === 0) {
      delete this.allLanes[node.id];
    }
  }

  /**
   * set pop up node marker
   *
   * @param node
   * @param graphNode
   * @param callback node click event function
   */
  public setupPopup(
    node: CircleMarker,
    graphNode: IGraphNode,
    callback: (node: CircleMarker, type: string, graphNode?: IGraphNode) => void
  ): void {
    const popup = `<div class="flex flex-col leaflet-popup-content" >
      <button class="close-popup absolute
      right-2 top-2" id="close-popup_${graphNode.id}">
        <svg class="w-4 h-4 text-white " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </button>
      <div class="text-center font-wrap-marker">
        <div class="text-lg text-white">${graphNode.name}</div>
      </div>
      <button class="bg-white hover:bg-gray-50 text-black py-2 px-0 rounded my-1 mx-0" id="continue-draw_${
        graphNode.id
      }">
      ${this.translocoService.translate('Add new path')}
      </button>
      <button class=" text-white py-2 px-0 rounded my-1 mx-0 " id="node-details_${
        graphNode.id
      }"
      style="
        background-color: #5243aa !important;"
      >
        ${this.translocoService.translate('Node Details')}
      </button>
      </div>`;
    node.unbindPopup();
    this.map.closePopup();
    node.bindPopup(popup, {
      className: 'transparent-popup',
      keepInView: true,
      minWidth: 220,
      closeButton: false,
      autoClose: true,
      closeOnClick: false,
    });
    node.on('popupopen', () => {
      const popupContent = document.querySelector('.leaflet-popup-content');
      if (!popupContent) {
        return;
      }

      const continueDraw = document.querySelector(
        `#continue-draw_${graphNode.id}`
      );
      continueDraw?.addEventListener('click', () => {
        this._templastNode = graphNode;
        callback(node, 'draw');
        this.resetLaneAndNodeStyle();
        this.map.closePopup();
      });

      const nodeDetails = document.querySelector(
        `#node-details_${graphNode.id}`
      );
      nodeDetails?.addEventListener('click', () => {
        callback(node, 'details', graphNode);
        this.map.closePopup();
      });
      //override popup close button leaflet because close button on leaflet is not working properly (it adds #close to the URL) in the leaflet v1.7.1. (Leaflet bug issue)
      const closePopup = document.querySelector(`#close-popup_${graphNode.id}`);
      closePopup?.addEventListener('click', () => {
        this.resetLaneAndNodeStyle();
        this.map.closePopup();
      });
    });
  }

  public removeNodeMarker(refId: string): void {
    const selectedMarker = get(this.allNodes, refId, '');
    if (selectedMarker) {
      this._tempGraph.removeLayer(selectedMarker);
      this._savedGraph.removeLayer(selectedMarker);
      // loop all _trackingRadiusGroup, and find the related tracking radius with selected node
      this._trackingRadiusGroup.getLayers().forEach((layer) => {
        if (layer instanceof CircleMarker) {
          const options = layer.options as TrackingRadiusMarkerOptions;
          if (options.nodeId === refId) {
            this._trackingRadiusGroup.removeLayer(layer);
          }
        }
      });
      // loop all _trackingWaitingPointGroup, and find the related tracking waiting with selected node
      this._trackingWaitingPointGroup.getLayers().forEach((layer) => {
        if (layer instanceof Marker) {
          const options = layer.options as TrackingWaitingPointMarkerOptions;
          if (options.nodeId === refId) {
            this._trackingWaitingPointGroup.removeLayer(layer);
          }
        }
      });
      delete this.allNodes[refId]; // delete node
      delete this.savedNodes[refId];
      this.updateLanesOnDeleteNode(refId);
    }
  }

  public updateLane(node, callback: (e: LeafletEvent) => void) {
    // redraw lane where this node starts from
    const allOccurences = this._drawnLanes.filter((o) =>
      o?.fromNode?.id.includes(node.id)
    );
    allOccurences.forEach((element, index) => {
      // this._tempGraph.removeLayer(this._drawnLanes[index].lane);
      const indexFrom = findLastIndex(
        this._drawnLanes,
        (o) =>
          o.fromNode.id === element.fromNode.id &&
          o.toNode.id === element.toNode.id &&
          element.name !== 'Waiting Point'
      );
      if (indexFrom !== -1) {
        this._tempGraph.removeLayer(this._drawnLanes[indexFrom].lane);
        this._savedGraph.removeLayer(this._drawnLanes[indexFrom].lane);
        const to = this._drawnLanes[indexFrom].toNode;
        // remove the from _drawnLanes
        this._drawnLanes.splice(indexFrom, 1);

        const isSingleLane = this.isSingleLane(node.id, to.id);

        // redraw the line
        this.drawLines(
          node,
          to,
          {
            markerId: `${node.id}|;|${to.id}`,
            click: (e) => callback(e),
            isSingleLane: isSingleLane,
          },
          isSingleLane
        );
      }
      // this._activeLane.setStyle({ color: 'red' });
    });

    // redraw lane where this node ends with
    const allOccurencesTo = this._drawnLanes.filter((o) =>
      o?.toNode?.id.includes(node.id)
    );
    allOccurencesTo.forEach((element, index) => {
      const indexTo = findLastIndex(
        this._drawnLanes,
        (o) =>
          o.toNode.id === element.toNode.id &&
          o.fromNode.id === element.fromNode.id
      );
      if (indexTo !== -1) {
        this._tempGraph.removeLayer(this._drawnLanes[indexTo].lane);
        this._savedGraph.removeLayer(this._drawnLanes[indexTo].lane);
        const from = this._drawnLanes[indexTo].fromNode;
        // remove the from _drawnLanes
        this._drawnLanes.splice(indexTo, 1);

        const isSingleLane = this.isSingleLane(from.id, node.id);

        // redraw the line
        this.drawLines(
          from,
          node,
          {
            markerId: `${from.id}|;|${node.id}`,
            click: (e) => callback(e),
            isSingleLane: isSingleLane,
          },
          isSingleLane
        );
      }
    });
  }

  deleteTrafficGraph() {
    this._tempGraph && this._tempGraph.clearLayers();
    this._tempLane && this._tempLane.clearLayers();
    this._savedGraph && this._savedGraph.clearLayers();
    this._trackingRadiusGroup && this._trackingRadiusGroup.clearLayers();
    this._trackingWaitingPointGroup &&
      this._trackingWaitingPointGroup.clearLayers();
    this._templastNode = undefined;
    this.allNodes = {};
    this.allLanes = {};
    this.allMetaLanes = {};
    this.savedNodes = {};
    this._drawnLanes = [];
    this._trackingRadius = {};
    this._trackingWaitingPoints = {};
  }

  public updateLanesOnDeleteNode(nodeId: string): void {
    delete this.allLanes[nodeId]; // deleted fromNodes in allLanes
    delete this.allMetaLanes[nodeId]; // deleted fromNodes in allMetaLanes

    // delete lane where this node starts from
    const allOccurences = this._drawnLanes.filter((o) =>
      o?.fromNode?.id.includes(nodeId)
    );
    allOccurences.forEach((element) => {
      const indexFrom = findLastIndex(
        this._drawnLanes,
        (o) =>
          o.fromNode.id === element.fromNode.id &&
          o.toNode.id === element.toNode.id
      );
      if (indexFrom !== -1) {
        this._tempGraph.removeLayer(this._drawnLanes[indexFrom].lane);
        this._savedGraph.removeLayer(this._drawnLanes[indexFrom].lane);
        // remove the from _drawnLanes
        this._drawnLanes.splice(indexFrom, 1);
      }
    });

    // delete lane where this node ends with
    const allOccurencesTo = this._drawnLanes.filter((o) =>
      o?.toNode?.id.includes(nodeId)
    );
    allOccurencesTo.forEach((element, index) => {
      const indexTo = findLastIndex(
        this._drawnLanes,
        (o) =>
          o.toNode.id === element.toNode.id &&
          o.fromNode.id === element.fromNode.id
      );
      if (indexTo !== -1) {
        this._tempGraph.removeLayer(this._drawnLanes[indexTo].lane);
        this._savedGraph.removeLayer(this._drawnLanes[indexTo].lane);
        // remove the from _drawnLanes
        this._drawnLanes.splice(indexTo, 1);
      }
    });

    // need to check allLanes variable that has ID of deleted node,
    // to delete all lanes that connected to deleted node.
    for (const key of Object.keys(this.allLanes)) {
      const valueList = this.allLanes[key];
      const indexLanesNode = valueList.indexOf(nodeId);
      if (indexLanesNode > -1) {
        this.allLanes[key].splice(indexLanesNode, 1);
      }

      // Remove lane data if the node is not connected with lane
      if (this.allLanes[key].length === 0) {
        delete this.allLanes[key];
      }
    }

    // need to check allMetaLanes variable that has ID of deleted node,
    // to delete all meta lanes that connected to deleted node.
    for (const key of Object.keys(this.allMetaLanes)) {
      const indexLanesNode = this.findIndexLanesNode(key, nodeId);
      if (indexLanesNode > -1) {
        this.allMetaLanes[key].splice(indexLanesNode, 1);
      }

      // Remove lane data if the node is not connected with lane
      if (this.allMetaLanes[key].length === 0) {
        delete this.allMetaLanes[key];
      }
    }
  }

  tempGraphList = [];
  setShowGraph(show) {
    if (show) {
      // this.map.addLayer(this._savedGraph);
      this.map.addLayer(this._tempGraph);

      for (const tempGraphLayer of this.tempGraphList) {
        this._savedGraph.addLayer(tempGraphLayer);
      }

      for (const tempLine of this._drawnLanes) {
        if (!(tempLine.fromNode.lift && tempLine.toNode.lift)) {
          this._savedGraph.addLayer(tempLine.lane);
          this._tempGraph.addLayer(tempLine.lane);
        }
      }

      // add for the tracking radius and waiting point group
      // as a part of the node traffic graph group filter
      this.map.addLayer(this._trackingRadiusGroup);
      this.map.addLayer(this._trackingWaitingPointGroup);
    } else {
      // this.map.removeLayer(this._savedGraph);
      // this.map.removeLayer(this._tempGraph);
      this.tempGraphList = [];
      this._savedGraph.eachLayer((graphLayer) => {
        if (graphLayer instanceof Marker) {
          this._savedGraph.removeLayer(graphLayer);
          this.tempGraphList.push(graphLayer);
        }
      });

      this._tempGraph.eachLayer((graphLayer) => {
        if (graphLayer instanceof Marker) {
          this._tempGraph.removeLayer(graphLayer);
          this.tempGraphList.push(graphLayer);
        }
      });

      for (const tempLine of this._drawnLanes) {
        if (!(tempLine.fromNode.lift && tempLine.toNode.lift)) {
          this._savedGraph.removeLayer(tempLine.lane);
          this._tempGraph.removeLayer(tempLine.lane);
        }
      }

      // add for the tracking radius and waiting point group
      // as a part of the node traffic graph group filter
      this.map.removeLayer(this._trackingRadiusGroup);
      this.map.removeLayer(this._trackingWaitingPointGroup);
    }
  }

  removeTempGraph() {
    this.map.removeLayer(this._tempGraph);
    this._tempGraph = layerGroup();
    this._templastNode = undefined;
    this._tempLane;
    this.allNodes = {};
    this.allLanes = {};
    this.allMetaLanes = {};
    this.savedNodes = {};
    this._drawnLanes = [];
  }

  /**
   * Helper function to remove lane from map when change the lane direction
   *
   * @param fromNodeId Node ID where the lane start
   * @param toNodeId Node ID where the lane finish
   */
  public removeLane(fromNodeId: string, toNodeId: string): void {
    // remove related lane when change direction
    const lanes = this._drawnLanes.filter(
      (o) =>
        o?.fromNode?.id.includes(fromNodeId) && o?.toNode?.id.includes(toNodeId)
    );

    lanes.forEach((element) => {
      const indexFrom = findLastIndex(
        this._drawnLanes,
        (o) =>
          o.fromNode.id === element.fromNode.id &&
          o.toNode.id === element.toNode.id
      );
      if (indexFrom !== -1) {
        this._tempGraph.removeLayer(this._drawnLanes[indexFrom].lane);
        this._savedGraph.removeLayer(this._drawnLanes[indexFrom].lane);
        // remove the lane from _drawnLanes
        this._drawnLanes.splice(indexFrom, 1);
      }
    });
  }

  /**
   * Helper function to check if the lane is single lane or double lane by checking the laneMeta property.
   * Because single lane will have {sharePath: 1} object.
   * E.g Node A <-> Node B. So laneMeta data will be
   * {
   *  nodeAId: [{nodeBId: {sharePath: 1}}],
   *  nodeBId: [{nodeAId: {sharePath: 1}}]
   * }
   *
   * @param fromNodeId Node ID where the lane start
   * @param toNodeId Node ID where the lane finish
   * @returns true if the lane is single lane (hase {sharePath: 1} object), otherwise false.
   */
  public isSingleLane(fromNodeId: string, toNodeId: string): boolean {
    if (!this.allMetaLanes) {
      return false;
    }

    const lanes = this.allMetaLanes[fromNodeId];
    const indexLanesNode = this.findIndexLanesNode(fromNodeId, toNodeId);
    let isSingleLane = false;

    if (
      indexLanesNode > -1 &&
      lanes[indexLanesNode][toNodeId] &&
      lanes[indexLanesNode][toNodeId].sharePath
    ) {
      isSingleLane = true;
    }

    return isSingleLane;
  }

  public findIndexLanesNode(fromNodeId: string, toNodeId: string): number {
    if (!this.allMetaLanes) {
      return -1;
    }

    const valueList = this.allMetaLanes[fromNodeId];
    let indexLanesNode = -1;

    if (valueList) {
      valueList.forEach((laneObj, i) => {
        const keys = Object.keys(valueList[i]);
        if (keys.length > 0 && keys[0] === toNodeId) {
          indexLanesNode = i;
        }
      });
    }

    return indexLanesNode;
  }

  /**
   * Add node to a map which used to show pointer for zone
   *
   * @param point
   * @param options
   */
  public renderZoneNode(
    lMarker: PointMarker,
    options: Partial<RmMarkerOptions>
  ): void {
    // first, try to find if layout Marker is already in list of markers.
    // if no, create new marker. Else, update the leaflet marker
    const found = this.findZoneNodeFromMarker(lMarker);
    if (found) {
      // update the marker
      this.updateZoneNode(found, lMarker);
      return;
    }

    // Create. Then, set the icon and color
    const lfMarker = this.createZoneNode(lMarker, options);
    this.setZoneNodeIcon(lfMarker, options.draggable);
  }

  protected findZoneNodeFromMarker(zoneNode: PointMarker): Marker | null {
    return this._zoneNodes[zoneNode.id] ?? null;
  }

  protected updateZoneNode(
    zoneNodeMarker: Marker,
    zoneNode: PointMarker
  ): void {
    this.setZoneNodeIcon(zoneNodeMarker, zoneNodeMarker.options.draggable);

    const coordinates: PointExpression = [zoneNode.x, zoneNode.y];
    const latlng = this.xYtoLatlng(coordinates);
    zoneNodeMarker.setLatLng(latlng);
  }

  /**
   * Create node which used to show pointer for zone. It is just temporary node.
   *
   * @param zoneNode
   * @param options
   * @returns
   */
  protected createZoneNode(
    zoneNode: PointMarker,
    options: Partial<RmMarkerOptions>
  ): Marker {
    // create new marker
    const lPoint: PointTuple = [zoneNode.x, zoneNode.y];
    options = {
      ...options,
      markerId: zoneNode.id,
    };

    // unproject the Point to latlng using zoom level of 1
    const latlng = this.xYtoLatlng(lPoint);

    // create the marker and the events
    const cfMarker = marker(latlng, options);
    if (options.click) {
      cfMarker.on('click', (event) => {
        options.click(event);
        this.resetLaneAndNodeStyle();
      });
    }
    if (options.dragstart) {
      cfMarker.on('dragstart', (event) => {
        options.dragstart(event);
        this.resetLaneAndNodeStyle();
      });
    }
    if (options.dragend) {
      cfMarker.on('dragend', options.dragend);
    }

    // add to markers group
    this._zoneNodesGroup.addLayer(cfMarker);

    // add to the index
    this._zoneNodes[zoneNode.id] = cfMarker;

    return cfMarker;
  }

  /**
   * Add zone area to map
   *
   * @param zone
   * @param options
   * @returns
   */
  public renderZone(
    zone: ZoneArea,
    options: Partial<RmPolylineOptions>
  ): Polygon {
    if (this._tempZone) {
      this.removeTempZone();
    }

    options = {
      ...options,
      markerId: zone.id,
      color: AVAILABLE_ZONE_AREA_COLOR[zone.color],
      name: zone.name,
    };

    const latlngs = zone.coordinates.map((point) => this.xYtoLatlng(point));
    const cfMarker = polygon(latlngs, options).addTo(this.map);
    if (options.click) {
      cfMarker.on('click', (event) => {
        options.click(event);
        this.resetLaneAndNodeStyle();
      });
    }
    if (options.dragstart) {
      cfMarker.on('dragstart', (event) => {
        options.dragstart(event);
        this.resetLaneAndNodeStyle();
      });
    }
    if (options.dragend) {
      cfMarker.on('dragend', options.dragend);
    }

    //add tooltip for the door name
    const cfMarkerBounds = cfMarker.getBounds();
    cfMarker.bindTooltip(_.escape(options.name), {
      permanent: true,
      opacity: 1,
      direction: 'center',
      className: 'zone-label',
    });

    // check the tooltip is cross the border of the polygon, if so, move the tooltip to the border  -->  start
    const tooltipLatLng = cfMarker.getTooltip().getLatLng();
    const xCrossBorder = tooltipLatLng.lat > cfMarkerBounds.getNorthEast().lat || tooltipLatLng.lat < cfMarkerBounds.getSouthWest().lat;
    const yCrossBorder = tooltipLatLng.lng > cfMarkerBounds.getNorthEast().lng || tooltipLatLng.lng < cfMarkerBounds.getSouthWest().lng;
    const xCenter =  (cfMarkerBounds.getNorthEast().lat + cfMarkerBounds.getSouthWest().lat) / 2;
    const yCenter =  (cfMarkerBounds.getNorthEast().lng + cfMarkerBounds.getSouthWest().lng) / 2;
    if (xCrossBorder || yCrossBorder) {
      let newLatLng = cfMarker.getTooltip().getLatLng();
      newLatLng.lat = xCenter;
      newLatLng.lng = yCenter;
      cfMarker.getTooltip().setLatLng(newLatLng);
    }
    // check the tooltip is cross the border of the polygon, if so, move the tooltip to the border  -->  start

    // add to markers group
    this._zonesGroup.addLayer(cfMarker);

    // add to the index
    this._zones[zone.id] = cfMarker;

    return cfMarker;
  }

  protected setZoneNodeIcon = (
    selectedMarker: Marker,
    draggable: boolean
  ): void => {
    const divElement = divIcon({
      className: 'custom-marker-pin',
      html: `
      <div class="zone-node ${
        draggable ? 'cursor-move' : 'cursor-pointer'
      }"></div>
      `,
      iconSize: [15, 15],
      iconAnchor: [15 / 2, 15 / 2],
    });
    selectedMarker.setIcon(divElement);
  };

  /**
   * Draw temperory polygon to show estimated zone
   *
   * @param e mouse hover event
   * @param vertices List of vertices used to draw the zone
   */
  public updateTempZone(
    e: LeafletMouseEvent,
    vertices: PointMarker[],
    vertexIndexInZone: number,
    color: string = 'red'
  ): void {
    if (this._tempZone) {
      this.removeTempZone();
    }
    const points: PointExpression[] = vertices.map((vertex) => [
      vertex.x,
      vertex.y,
    ]);

    const latlngs = points.map((point) => this.xYtoLatlng(point));

    if (vertexIndexInZone > -1) {
      latlngs[vertexIndexInZone] = e.latlng;
    } else {
      // Add the mouse pointer location
      latlngs.push(e.latlng);
    }

    this._tempZone = polygon(latlngs, {
      color: AVAILABLE_ZONE_AREA_COLOR[color],
    });

    this._tempZonesGroup.addLayer(this._tempZone);
  }

  public removeTempZone(): void {
    if (this._tempZone) {
      this._tempZonesGroup.removeLayer(this._tempZone);
      this._tempZone = undefined;
    }
  }

  public removeZoneNode(refId: string): void {
    const selectedMarker = get(this._zoneNodes, refId, '');
    if (selectedMarker) {
      this._zoneNodesGroup.removeLayer(selectedMarker);
      delete this._zoneNodes[refId];
    }
  }

  public setShowZones(showZones: boolean): void {
    if (showZones) {
      this.map.addLayer(this._zoneNodesGroup);
      this.map.addLayer(this._zonesGroup);
      this.map.addLayer(this._tempZonesGroup);
    } else {
      this.map.removeLayer(this._zoneNodesGroup);
      this.map.removeLayer(this._zonesGroup);
      this.map.removeLayer(this._tempZonesGroup);
    }
  }

  /* ===== new feature fire alarm holding marker ===== */
  public setShowFireHolding(showFireHolding: boolean): void {
    if (showFireHolding) {
      this.map.addLayer(this._fireHoldingGroup);
    } else {
      this.map.removeLayer(this._fireHoldingGroup);
    }
    this.setShowLabels(showFireHolding);
  }

  public removeZone(refId: string): void {
    const selectedMarker = get(this._zones, refId, '');
    if (selectedMarker) {
      this._zonesGroup.removeLayer(selectedMarker);
      delete this._zones[refId];
    }
  }

  public removeFireHolding(id: string): void {
    const _marker = get(this._fireHoldingNodes, id, '');
    if (_marker) {
      this._fireHoldingGroup.removeLayer(_marker);
      delete this._fireHoldingNodes[id];
    }
  }

  public removeLinkAreaMarker(id: string): void {
    const _marker = get(this._linkAreaTransitNodes, id, '');
    if (_marker) {
      this._linkAreaTransitGroup.removeLayer(_marker);
      delete this._linkAreaTransitNodes[id];
    }
  }

  public renderTrackingRadius(
    trackingRadiusMarker: IGraphNode,
    options: Partial<TrackingRadiusMarkerOptions>
  ): void {
    this.createTrackingRadius(trackingRadiusMarker, options);
  }

  private createTrackingRadius(
    trackingRadiusMarker: IGraphNode,
    options: Partial<TrackingRadiusMarkerOptions>
  ): Circle {
    // create new marker
    const trackingRadiusPoint: PointTuple = [
      trackingRadiusMarker.x,
      trackingRadiusMarker.y,
    ];
    options = {
      ...options,
      markerId: trackingRadiusMarker.id,
    };
    options.weight = 2;
    options.dashArray = '5,5';
    options.fillColor = '#ffd556';
    options.color = '#ffd556';

    // unproject the Point to latlng using zoom level of 1
    const latlng = this.xYtoLatlng(trackingRadiusPoint);

    // create the circle on the map
    const trMarker = circle(latlng, options);
    this._trackingRadiusGroup.addLayer(trMarker);

    // add to the index
    this._trackingRadius[trackingRadiusMarker.id] = trMarker;

    return trMarker;
  }

  /**
   * Function to get the radius in pixel
   * Note: need to divide by the zoom level
   */
  private getRadiusDistancePoint(circleData: Circle): number {
    // get center circle latlng
    const latlng = circleData.getLatLng();
    // create a polygon version, then pick one of the latlng to get the outer latlng
    const polygonVersion = this.circleToPolygon(circleData, 10);
    // convert center and outer latlng to points according to current layout
    const outerPoint = this.latlngToXy(polygonVersion[0]);
    const innerPoint = this.latlngToXy(latlng);

    // last, get the distance between the two, not required to devided by zoomlevel
    return innerPoint.distanceTo(outerPoint);
  }

  public getRadiusInPixel(node: IGraphNode): number {
    const selectedMarker = get(this._trackingRadius, node.id, '');
    if (selectedMarker) {
      return this.getRadiusDistancePoint(selectedMarker);
    }

    return 0;
  }

  public removeTrackingRadius(refId: string): void {
    const selectedMarker = get(this._trackingRadius, refId, '');
    if (selectedMarker) {
      this._trackingRadiusGroup.removeLayer(selectedMarker);
      delete this._trackingRadius[refId];
    }
  }

  public updateTrackingRadius(refId: string, radius: number): void {
    const selectedMarker = get(this._trackingRadius, refId, '');
    if (selectedMarker) {
      selectedMarker.setRadius(radius);
    }
  }

  public moveTrackingRadius(node): void {
    const selectedMarker = get(this._trackingRadius, node.id, '');
    if (selectedMarker) {
      selectedMarker.setLatLng(this.xYtoLatlng([node.x, node.y]));
    }
  }

  public renderTrackingWaitingPoint(
    trackingWaitingPointMarker: GraphNodeWaitingPoint,
    options: Partial<TrackingWaitingPointMarkerOptions>
  ): void {
    const markerTwp = this.createTrackingWaitingPoint(
      trackingWaitingPointMarker,
      options
    );
    this.setNodeWaitingPointIcon(
      markerTwp,
      '#4C9AFF',
      trackingWaitingPointMarker.name
    );
  }

  private createTrackingWaitingPoint(
    trackingWaitingPointMarker: GraphNodeWaitingPoint,
    options: Partial<TrackingWaitingPointMarkerOptions>
  ): Marker {
    // create new marker
    const trackingWaitingPoint: PointTuple = [
      trackingWaitingPointMarker.x,
      trackingWaitingPointMarker.y,
    ];
    options = {
      ...options,
      markerId: options.markerId,
    };

    // unproject the Point to latlng using zoom level of 1
    const latlng = this.xYtoLatlng(trackingWaitingPoint);

    // create the marker and the events
    const trWpMarker = marker(latlng, options).bindTooltip(
      trackingWaitingPointMarker.name,
      {
        permanent: false,
        direction: 'top',
        className: 'transparent-tooltip',
        offset: [0, -29],
      }
    );
    if (options.dragstart) {
      trWpMarker.on('dragstart', options.dragstart);
    }
    if (options.dragend) {
      trWpMarker.on('dragend', options.dragend);
    }

    if (options.drag) {
      trWpMarker.on('drag', options.drag);
    }

    // add to markers group
    this._trackingWaitingPointGroup.addLayer(trWpMarker);

    // add to the index
    this._trackingWaitingPoints[options.markerId] = trWpMarker;

    return trWpMarker;
  }

  public updateTrackingWaitingPoint(refId: string, position: PointTuple): void {
    const selectedMarker = get(this._trackingWaitingPoints, refId, '');
    if (selectedMarker) {
      selectedMarker.setLatLng(this.xYtoLatlng(position));
    }
  }

  public removeTrackingWaitingPoint(refId: string): void {
    const selectedMarker = get(this._trackingWaitingPoints, refId, '');
    if (selectedMarker) {
      // this._trackingWaitingPointGroup.removeLayer(selectedMarker);
      // delete this._trackingWaitingPoints[refId];
      this._trackingWaitingPointGroup.getLayers().forEach((layer) => {
        if (layer instanceof Marker) {
          const options = layer.options as TrackingWaitingPointMarkerOptions;
          if (options.markerId === refId) {
            this._trackingWaitingPointGroup.removeLayer(layer);
          }
        }
      });
    }
  }

  private circleToPolygon(circleData: Circle, vertices: number = 10) {
    const points = [];
    const crs = this.map.options.crs as any;
    const DOUBLE_PI = Math.PI * 2;
    let angle = 0.0;
    let projectedCentroid = null;
    let radius;
    let point;
    let project;
    let unproject;

    if (crs === CRS.EPSG3857) {
      project = this.map.latLngToLayerPoint.bind(this.map);
      unproject = this.map.layerPointToLatLng.bind(this.map);
      radius = circleData.getRadius();
    } else {
      // especially if we are using Proj4Leaflet
      project = crs.projection.project.bind(crs.projection);
      unproject = crs.projection.unproject.bind(crs.projection);
      radius = circleData.getRadius();
    }

    projectedCentroid = project(circleData.getLatLng());

    for (let i = 0; i < vertices - 1; i++) {
      angle -= DOUBLE_PI / vertices; // clockwise
      point = new Point(
        Number(projectedCentroid.x) + radius * Math.cos(angle),
        Number(projectedCentroid.y) + radius * Math.sin(angle)
      );
      if (i > 0 && point.equals(points[i - 1])) {
        continue;
      }
      points.push(unproject(point));
    }

    return points;
  }

  public setShowLinkArea(showLinkArea: boolean): void {
    if (showLinkArea) {
      this.map.addLayer(this._linkAreaTransitGroup);
    } else {
      this.map.removeLayer(this._linkAreaTransitGroup);
    }
    this.setShowLabels(showLinkArea);
  }

  /**
   * Add a link area transit point marker
   * @param marker - LayoutMarker
   */
  public renderLinkAreaTransitMarker(
    _marker: LinkAreaTransitPoint,
    options: Partial<RmMarkerOptions>
  ): void {
    const found = this._linkAreaTransitNodes[_marker.id] ?? null;

    if (found) {
      this.updateLinkAreaTransitMarker(found, _marker);
      return;
    }
    const linkMarker = this.createLinkAreaTransitMarker(_marker, options);

    this.setLinkAreaIcon(linkMarker, _marker.type, _marker.name);
  }

  /**
   * create marker
   * @param _marker
   * @param options
   * @returns
   */
  private createLinkAreaTransitMarker(
    _marker: LinkAreaTransitPoint,
    options: Partial<RmMarkerOptions>
  ) {
    const point: PointTuple = [_marker.position.x, _marker.position.y];

    options = {
      ...options,
      markerId: _marker.id,
      title: _marker.name,
    };
    const latlng = this.xYtoLatlng(point);
    const faMarker = marker(latlng, options);
    if (options.click) {
      faMarker.on('click', (event) => {
        options.click(event);
      });
    }
    if (options.dragstart) {
      faMarker.on('dragstart', (event) => {
        options.dragstart(event);
      });
    }
    if (options.dragend) {
      faMarker.on('dragend', (event) => {
        options.dragend(event);
      });
    }
    if (options.drag) {
      faMarker.on('drag', (event) => {
        options.drag(event);
      });
    }

    this._linkAreaTransitGroup.addLayer(faMarker);
    this._linkAreaTransitNodes[_marker.id] = faMarker;

    return faMarker;
  }

  private setLinkAreaIcon = (
    selectedMarker: Marker,
    type: string,
    label?: string
  ): void => {
    let imageUrl: string;
    if (type === 'linkarea-entry') {
      imageUrl = '/assets/images/markers/linkarea-entry.svg';
    } else if (type === 'linkarea-exit') {
      imageUrl = '/assets/images/markers/linkarea-exit.svg';
    }

    const htmlIcon = divIcon({
      className: 'custom-marker-pin',
      html: `
        <span class="marker-label linkarea">${label}</span>
        <div class="marker-pin linkarea-pin ${type}">
            <img src="${imageUrl}">
        </div>
        <div class="linkarea-marker-shadow"></div>
      `,
      iconSize: [64, 64],
      iconAnchor: [64 / 2, 64],
    });
    selectedMarker.setIcon(htmlIcon);
  };

  updateLinkAreaTransitMarker(
    oldMarker: Marker,
    updateMarker: LinkAreaTransitPoint
  ): void {
    this.setLinkAreaIcon(oldMarker, updateMarker.type, updateMarker.name);
    const coordinates: PointExpression = [
      updateMarker.position.x,
      updateMarker.position.y,
    ];
    const latlng = this.xYtoLatlng(coordinates);
    oldMarker.setLatLng(latlng);
  }
}

interface RmMarkerOptions extends MarkerOptions {
  markerId: string;
  color?: string;
  iconId?: string;
  click?: LeafletEventHandlerFn;
  dragstart?: LeafletEventHandlerFn;
  dragend?: LeafletEventHandlerFn;
  label?: string;
  drag?: LeafletEventHandlerFn;
}

interface RmTooltipOptions extends TooltipOptions {
  type?: string;
}

interface SensorMarkerOptions extends MarkerOptions {
  markerId: string;
  color?: string;
  click?: LeafletEventHandlerFn;
  dragstart?: LeafletEventHandlerFn;
  dragend?: LeafletEventHandlerFn;
  label?: string;
}

interface FloorMarkerOptions extends MarkerOptions {
  markerId: string;
  color?: string;
  click?: LeafletEventHandlerFn;
  dragstart?: LeafletEventHandlerFn;
  dragend?: LeafletEventHandlerFn;
  label?: string;
}
interface LiftMarkerOptions extends MarkerOptions {
  markerId: string;
  color?: string;
  iconId?: string;
  iconClass?: string;
  click?: LeafletEventHandlerFn;
  dragstart?: LeafletEventHandlerFn;
  dragend?: LeafletEventHandlerFn;
  drag?: LeafletEventHandlerFn;
  label?: string;
}

interface TrackingRadiusMarkerOptions extends CircleMarkerOptions {
  markerId: string;
  iconClass?: string;
  label?: string;
  nodeId?: string;
}

interface TrackingWaitingPointMarkerOptions extends MarkerOptions {
  markerId: string;
  iconClass?: string;
  dragstart?: LeafletEventHandlerFn;
  dragend?: LeafletEventHandlerFn;
  drag?: LeafletEventHandlerFn;
  label?: string;
  nodeId?: string;
}

interface PointMarker {
  id?: string;
  x: number;
  y: number;
  z: number;
}

/**
 * workaround interface because data type
 * of the layer is not persistent, there is already
 * options and type property in the layer data type
 * but cannot be accessed, unless create new interface
 * that declare this property to make the property can be accessed
 */
interface LayerOptionsWithTooltip extends LayerOptions {
  type?: string;
}

interface LayerTooltip extends Layer {
  options: LayerOptionsWithTooltip;
}

interface RmPolylineOptions extends PolylineOptions {
  name?: string;
  markerId?: string;
  color?: string;
  isSingleLane?: boolean;
  click?: LeafletEventHandlerFn;
  dragstart?: LeafletEventHandlerFn;
  dragend?: LeafletEventHandlerFn;
}
